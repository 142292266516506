import { rem } from 'polished';
import styled from 'styled-components';
import { hoverMediaQuery, noHoverMediaQuery } from '../../../common/breakpoints';
import { darkGrey1, lightGrey2, lightGrey4, lightGrey5 } from '../../../common/colors';

export const StyledSearchDropdownCard = styled.li`
  ${hoverMediaQuery} {
    &:hover {
      background-color: ${lightGrey4};
    }
  }

  ${noHoverMediaQuery} {
    &:active {
      background-color: ${lightGrey4};
    }
  }

  a.card-link {
    display: flex;
    cursor: pointer;
    height: 100%;
    position: relative;
    box-sizing: border-box;
  }
`;

export const ImageContainer = styled.div`
  max-width: 36px;
  max-height: 54px;
  padding: 8px 20px;
  line-height: 54px;

  svg {
    vertical-align: middle;
    width: 36px;
    color: ${lightGrey2};
  }
`;

export const Title = styled.section`
  padding: 8px 20px 8px 0;
  border-bottom: 1px solid ${lightGrey5};
  flex: 1 0 0%;

  span {
    color: ${darkGrey1};
    font-size: ${rem(10)};
    line-height: 13px;
    margin: 0;
    padding: 2px 4px;
    border-radius: 1px;
    background-color: #eeeeee;
  }

  label {
    line-height: 19px;
    font-size: ${rem(16)};
    color: ${darkGrey1};
    font-weight: bold;
    padding-top: 7px;
    display: block;
    word-wrap: break-word;
    hyphens: auto;
    cursor: pointer;
  }
`;

export const Img = styled.img`
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin: 0 auto;
`;
