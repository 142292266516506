import * as React from 'react';
import { useTranslations } from '../../../common/translation';
import translations from '../translations/navigation';
import { HamburgerProps } from './Hamburger.props';
import { StyledHamburger } from './Hamburger.styles';

export const Hamburger = ({ onClick, open }: HamburgerProps) => {
  const { t } = useTranslations(translations);

  return (
    <StyledHamburger
      aria-label={t('ariaHamburgerMenu', 'Menu')}
      className={open ? 'open' : 'closed'}
      data-component='hamburger'
      onClick={onClick}
      open={open}
    >
      <span />
      <span />
      <span />
      <span />
    </StyledHamburger>
  );
};
