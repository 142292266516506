import { navigate } from '@reach/router';
import * as React from 'react';
import { IS_SERVER } from '../../../common/constants';
import { getCountryFlag, getCountryName, isMultiCountry } from '../../../common/countries';
import { clearCountryCode, getCountryCodeFromPath } from '../../../common/helpers/countrySelection';
import { CountryIndicatorProps } from './CountryIndicator.props';
import { StyledCountryIndicator } from './CountryIndicator.styles';

const navigateToCountrySelection = () => {
  clearCountryCode();
  navigate('/').then();
};

export const CountryIndicator = ({ hide }: CountryIndicatorProps) => {
  if (IS_SERVER || !isMultiCountry() || hide) {
    return null;
  }

  const countryCode = getCountryCodeFromPath();
  if (!countryCode) {
    return null;
  }

  const CountryFlagIcon = getCountryFlag(countryCode);

  return (
    <StyledCountryIndicator onClick={navigateToCountrySelection}>
      <CountryFlagIcon />
      <span>{getCountryName(countryCode)}</span>
    </StyledCountryIndicator>
  );
};
