import { Link } from '@reach/router';
import * as React from 'react';
import { SuperLinkProps } from './SuperLink.props';

export const SuperLink = (props: SuperLinkProps) => {
  const { children, getProps, target, to, ...rest } = props;

  return to.match(/^(http|mailto:)/i) ? (
    // Exclude any Link-specific attributes to avoid React warnings
    <a href={to} target={target} {...rest}>
      {children}
    </a>
  ) : (
    // Exclude `target` to avoid having Firefox prioritize opening a new window/tab over switching to route
    <Link getProps={getProps} to={to} {...rest}>
      {children}
    </Link>
  );
};
