import * as React from 'react';
import { useLocale } from '../../../common/hooks/useLocale';
import { SuperLink } from '../../super-link/SuperLink';

const enCopy = () => (
  <p>
    Click{' '}
    <SuperLink
      target='_blank'
      to='https://policies.warnerbros.com/privacy/en-ca/html/privacy_en-ca_1.0.0.html#adchoices'
    >
      here
    </SuperLink>{' '}
    for info about our online advertising practices.
  </p>
);

const frCopy = () => (
  <p>
    Cliquez{' '}
    <SuperLink
      target='_blank'
      to='https://policies.warnerbros.com/privacy/fr-ca/html/privacy_fr-ca_1.0.0.html#adchoices'
    >
      ici
    </SuperLink>{' '}
    pour obtenir des informations sur nos pratiques de publicité en ligne.
  </p>
);

export const FloatingNoticeBodyCA = () => {
  const locale = useLocale();

  switch (locale) {
    case 'en-CA':
      return enCopy();
    case 'fr-CA':
      return frCopy();
    default:
      return null;
  }
};
