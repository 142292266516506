import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../common/breakpoints';
import { white } from '../../../common/colors';

export const StyledCountryIndicator = styled.button`
  position: absolute;
  right: ${rem(60)};
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  font-weight: 700;
  font-size: ${rem(14)};
  line-height: ${rem(18)};
  color: ${white};
  cursor: pointer;

  &:focus {
    outline: 1px dotted ${white};
    outline: 1px auto -webkit-focus-ring-color;
  }

  & > * {
    vertical-align: middle;
  }

  & > svg {
    width: ${rem(18)};
    height: ${rem(12)};
    margin: 0 ${rem(14)} 0 0;
  }

  ${mediaQueryWidth({ min: 'tablet' })`
    right: ${rem(90)};
  `}
`;
