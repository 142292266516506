import * as React from 'react';
import { contentTypeToIcon } from '../../../common/helpers/icons';
import { useTranslations } from '../../../common/translation';
import { SuperLink } from '../../super-link/SuperLink';
import translations from '../translations';
import { SearchDropdownCardProps } from './SearchDropdownCard.props';
import { ImageContainer, Img, StyledSearchDropdownCard, Title } from './SearchDropdownCard.styles';

export const SearchDropdownCard = ({ searchResult: { contentType, image, title, url } }: SearchDropdownCardProps) => {
  const { t } = useTranslations(translations);
  const ContentTypeIcon = contentTypeToIcon[contentType];

  return (
    <StyledSearchDropdownCard>
      <SuperLink to={url || '#'} title={title} className='card-link'>
        <ImageContainer>
          {image && <Img src={image.src} alt={image.alt} />}
          {!image && <ContentTypeIcon />}
        </ImageContainer>
        <Title>
          <span>{t(contentType, contentType)}</span>
          <label>{title}</label>
        </Title>
      </SuperLink>
    </StyledSearchDropdownCard>
  );
};
