import * as React from 'react';
import { MARKET } from '../../../config/browser';
import { FloatingNoticeBodyCA } from './FloatingNoticeBodyCA';
import { FloatingNoticeBodyUS } from './FloatingNoticeBodyUS';

export const FloatingNoticeBody = () => {
  switch (MARKET) {
    case 'US':
      return <FloatingNoticeBodyUS />;
    case 'CA':
      return <FloatingNoticeBodyCA />;
    default:
      return null;
  }
};
