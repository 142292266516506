import axios from 'axios';
import { SEARCH_SERVICE_BASEURL } from '../../../config/browser';
import { isMultiCountry } from '../../countries';
import { LOCALE_CONFIG } from '../../translation';
import { SearchOptions } from '../common';

const buildGraphQlUrl = (graphql: string) => `${SEARCH_SERVICE_BASEURL}/graphql?query=${encodeURIComponent(graphql)}`;

const buildLanguageFilter = (options: SearchOptions) => {
  if (isMultiCountry()) {
    return `,language:"${options.locale}"`;
  }

  if (LOCALE_CONFIG.length > 1) {
    return `,language:"${options.locale?.substring(0, 2)}"`;
  }

  return '';
};

const buildFacetsSearch = (searchText: string, options: SearchOptions) => {
  const language = buildLanguageFilter(options);
  const searchFunction = `facets:search(searchText:"${searchText}"${language},size:1)`;
  return `${searchFunction}{facets{all news_release article movies video_game tv_season tv_series bio collections brands page}}`;
};

const buildQuerySearch = (
  searchText: string,
  typesToFilter: string[],
  page: string,
  size: string,
  options: SearchOptions,
) => {
  const typesArg = typesToFilter.length > 0 ? `,types:[${typesToFilter.join(',')}]` : '';
  const pageArg = /^\d+$/.test(page.trim()) ? `,page:${page}` : '';
  const sizeArg = /^\d+$/.test(size.trim()) ? `,size:${size}` : '';
  const language = buildLanguageFilter(options);
  const orderField = options.orderField ? `,orderField:${options.orderField}` : '';
  const order = options.order ? `,order:${options.order}` : '';
  let addlFilterQuery = '';
  if (options.filterInfo && options.filterInfo.field && options.filterInfo.value) {
    addlFilterQuery += `,filterField:"${options.filterInfo.field}"`;
    addlFilterQuery += `,filterText:"${options.filterInfo.value}"`;
    if (options.filterInfo.operation) {
      addlFilterQuery += `,filterOpn:"${options.filterInfo.operation}"`;
    }
  }

  const nodeFields =
    '{nid truncatedTitle type customMarketingMessage truncatedDescription siteSelection releaseDate url isMobileGameAndApp thumbnails{nodeSrc alt} categorisedPurchaseLinks{name links{image{src alt}url}}categories}';
  return `query:search(searchText:"${searchText}"${typesArg}${pageArg}${sizeArg}${orderField}${addlFilterQuery}${order}${language}){nodes ${nodeFields}}`;
};

const buildQuerySearchNodes = (searchText: string) => {
  const nodeFields =
    '{nid truncatedTitle type customMarketingMessage truncatedDescription siteSelection releaseDate url isMobileGameAndApp thumbnails{nodeSrc alt} categorisedPurchaseLinks{name links{image{src alt}url}}categories}';
  const ids = searchText
    .split('+')
    .map(String)
    .join('","');
  return `getNodes(ids: ["${ids}"])${nodeFields}`;
};

const fetchData = async (url: string) => {
  const { data } = await axios.get(url);
  return data;
};

export const runSearch = async (
  searchText: string,
  types: string[],
  page: string,
  size: string,
  options: SearchOptions,
): Promise<any> => {
  const graphql = `{${buildFacetsSearch(searchText, options)}${buildQuerySearch(
    searchText,
    types,
    page,
    size,
    options,
  )}}`;
  const url = buildGraphQlUrl(graphql);
  return fetchData(url);
};

export const runSearchNodes = async (searchNodes: string): Promise<any> => {
  const graphql = `{${buildQuerySearchNodes(searchNodes)}}`;
  const url = buildGraphQlUrl(graphql);
  return fetchData(url);
};

export const runAutoSuggest = async (searchTerms: string, options: SearchOptions): Promise<any> => {
  const language = buildLanguageFilter(options);
  const graphql = `{query:search(searchText:"${searchTerms}"${language},size:5){nodes{nid type title thumbnails{nodeSrc alt}url}}}`;
  const url = buildGraphQlUrl(graphql);
  return fetchData(url);
};
