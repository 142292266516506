import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDebug } from '../../../common/hooks/useDebug';
import { useWindowScroll } from '../../../common/hooks/useWindowScroll';
import { useTranslations } from '../../../common/translation';
import { CountryIndicator } from '../country-indicator/CountryIndicator';
import { Hamburger } from '../hamburger/Hamburger';
import { Logo } from '../logo/Logo';
import { NavList } from '../nav-list/NavList';
import { NavSearch } from '../nav-search/NavSearch';
import { InnerNavProps } from '../Navigation.props';
import { StyledInnerNav } from '../Navigation.styles';
import translations from '../translations/navigation';

export const DesktopNav = ({ navListItems }: InnerNavProps) => {
  const { scrollY } = useWindowScroll(150);
  const [open, setOpen] = useState<boolean>(scrollY <= 0);
  const [showHamburger, setShowHamburger] = useState<boolean>(scrollY > 0);
  const [hideElements, setHideElements] = useState<boolean>(false);
  const { t } = useTranslations(translations);

  const hasNavList = navListItems.length > 0;

  const toggle = () => {
    setOpen(!open);
  };

  const switchToHamburger = () => {
    setOpen(false);
    setShowHamburger(true);
  };

  const onSearchOpen = (searchOpen: boolean) => {
    if (searchOpen) {
      switchToHamburger();
    }

    setHideElements(searchOpen);
  };

  useEffect(() => {
    if (scrollY <= 0) {
      setOpen(true);
      setShowHamburger(false);
    } else if (!showHamburger) {
      // Scrolling down from top
      switchToHamburger();
    }
  }, [scrollY]);

  useDebug('app:cmp:DesktopNav', { props: { navListItems }, state: { open, showHamburger } });

  return (
    <StyledInnerNav aria-label={t('ariaMainNavigation', 'Main navigation')} role='navigation'>
      <Logo />
      {hasNavList && <NavList direction='horizontal' navListItems={navListItems} open={open} />}
      {hasNavList && showHamburger && <Hamburger onClick={toggle} open={open} />}
      <CountryIndicator hide={hideElements} />
      <NavSearch onSearchOpen={onSearchOpen} truncateWidth={hasNavList} />
    </StyledInnerNav>
  );
};
