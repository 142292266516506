import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../common/breakpoints';
import { black, darkGrey1, darkGrey4, grey20, white, whiteSmoke } from '../../../common/colors';
import { Button } from '../../button/Button';
import { hamburgerWidthPx } from '../hamburger/Hamburger.styles';
import { logoWidthPx } from '../logo/Logo.styles';

const searchButtonWidthPx = 50;

export const StyledNavSearch = styled.section`
  .ripple-wrapper {
    position: absolute;
    height: 100%;
    width: ${rem(searchButtonWidthPx)};
    top: 0;
    right: 0;
    background: ${darkGrey1};

    .ripple-spinner {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const StyledSearchButton = styled.button`
  position: absolute;
  right: 0;
  width: ${rem(searchButtonWidthPx)};
  height: 100%;
  line-height: 0;
  background-color: ${darkGrey1};
  border: none;
  z-index: 1;
  cursor: pointer;

  :focus {
    outline: 1px dotted ${white};
    outline: 1px auto -webkit-focus-ring-color;
  }

  svg {
    width: ${rem(24)};
    fill: ${white};
  }
`;

export const StyledSearchIconContainer = styled.div`
  display: block;
  z-index: 99999;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);

  & > svg {
    width: ${rem(24)};
    height: ${rem(24)};
    color: ${black};
  }
`;

export const AnimatedSection = styled.section<{ truncateWidth?: boolean }>`
  opacity: 0;
  transform: scaleX(0);
  transition: all 300ms ease;
  transform-origin: right;
  position: absolute;
  left: ${({ truncateWidth }) => (truncateWidth ? rem(logoWidthPx + hamburgerWidthPx) : rem(logoWidthPx))};
  right: ${rem(50)};
  box-sizing: border-box;
  height: 100%;

  [placeholder] {
    text-overflow: ellipsis;
  }

  ${mediaQueryWidth({ max: 'mobile' })`
    left: 0;
    right: 42px;
  `}

  &.open {
    flex: 1;
    opacity: 1;
    transform: scaleX(1);
    transition: all 300ms ease;
  }

  input.search-input {
    box-sizing: border-box;
    position: absolute;
    font-size: ${rem(16)};
    font-weight: 800;
    width: 100%;
    z-index: 9999;
    color: ${black};
    background-color: ${whiteSmoke};
    border: none;
    height: calc(100% - 10px);
    padding: 0 15px 0 2.8rem;
    margin: 5px 0;

    ${mediaQueryWidth({ max: 'mobile' })`
      padding-left: 2.25rem;
      margin: 5px 0 5px 5px;
    `}

    :focus {
      background-color: ${white};
      outline: 1px auto -webkit-focus-ring-color;

      &::placeholder {
        color: ${grey20};
        text-overflow: ellipsis;
      }

      &::-ms-input-placeholder {
        color: ${darkGrey4};
        text-overflow: ellipsis;
      }
    }

    &::-ms-clear {
      display: none;
    }

    &::placeholder {
      color: ${darkGrey4};
      text-overflow: ellipsis;
    }

    &::-ms-input-placeholder {
      color: ${darkGrey4};
      text-overflow: ellipsis;
    }
  }
`;

export const AutoSuggest = styled.section`
  left: 0;
  right: 0;
  top: 3.3125rem;
  position: absolute;
  background: ${white};
  padding: 0;
  height: auto;
  max-height: calc(100vh - 100%);
  overflow: auto;
  box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.2), 2px 2px 3px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;

  &.open {
    opacity: 1;
    transition: all 0.2s ease-in-out;
    transform: scaleY(1);
  }

  ${mediaQueryWidth({ max: 'mobile' })`
      right: -42px;
  `}
`;

export const StyledResultsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-items: flex-start;
  flex-direction: column;
`;

export const StyledNoResults = styled.div`
  margin: ${rem(100)} auto;
  font-size: ${rem(16)};
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  color: ${black};
`;

export const Results = styled.ul`
  display: block;
  width: 100%;
`;

export const NavSearchButton = styled(Button)`
  margin: 20px auto;

  ${mediaQueryWidth({ min: 'mobile' })`
    margin: 20px 0 20px 74px;
  `}
`;
