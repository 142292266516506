// noinspection UnnecessaryLocalVariableJS
import * as qs from 'query-string';
import { ASSETS_BASEURL, FILES_FOLDER_PATH, IMAGE_RENDITION_BASEURL } from '../../../config/browser';
import { dateStringToYear, formattedDate } from '../../helpers/date';
import { ImageRendition, ImageRenditionPresets } from '../../image-rendition';
import { getTextByType, mapToTranslationLocale, Translation } from '../../translation';
import {
  BuyLinkCategory,
  BuyLinkGroup,
  D7ContentType,
  NormalizedFacet,
  NormalizedSearchResult,
  SearchOptions,
  SearchType,
  toContentType,
} from '../common';
import translations from '../translations';

const imageRenditionObj = new ImageRendition(FILES_FOLDER_PATH, IMAGE_RENDITION_BASEURL, ASSETS_BASEURL);

const buildFacetQueryString = (query: string, types: string[] = []): string => {
  const queryString = { qt: query };
  if (types.length > 0) {
    types.forEach((type, index) => (queryString[`f[${index}]`] = `type:${type}`));
  }

  return qs.stringify(queryString, { strict: false, encode: false });
};

export const normalizeFacets = (response: any, query: string): NormalizedFacet[] => {
  const {
    data: {
      facets: { facets },
    },
  } = response;

  const facetOrder = [
    'all',
    'movies',
    'tv_series',
    'video_game',
    'collections',
    'brands',
    'news_release',
    'article',
    'page',
    'bio',
  ];
  return facetOrder
    .filter((t) => facets[t] > 0)
    .map((type) => {
      let numResults = facets[type];
      const text = toContentType(type) || '';
      switch (type) {
        case 'all':
          return { numResults, type, text: 'All', query: buildFacetQueryString(query) };
        case 'tv_series':
          numResults = facets.tv_series + facets.tv_season;
          return { text, numResults, type, query: buildFacetQueryString(query, ['tv_series', 'tv_season']) };
        default:
          return { text, numResults, type, query: buildFacetQueryString(query, [type]) };
      }
    });
};

export const getIrsPreset = (type: string, isMobileGameAndApp: boolean): ImageRenditionPresets => {
  switch (type) {
    case 'bio':
      return ImageRenditionPresets.MobileAppGamesSquare;
    case 'article':
    case 'brands':
    case 'collections':
      return ImageRenditionPresets.ArticlePromo;
    default:
      return isMobileGameAndApp ? ImageRenditionPresets.MobileAppGamesSquare : ImageRenditionPresets.KeyArt;
  }
};

const normalizePurchaseLinks = (
  siteSelection: string[] | undefined,
  isMobileGameAndApp: boolean,
  categorisedPurchaseLinks: any,
  locale: string,
  contentType?: ContentType,
): BuyLinkGroup[] => {
  const translationLocale = mapToTranslationLocale(locale);
  const t = Translation(translations[translationLocale]);
  const buyLinks: BuyLinkGroup[] = [];

  const categories: BuyLinkCategory[] = [];
  const commonOpts = { t, type: contentType, isMobileGameOrApp: isMobileGameAndApp, defaultValue: '' };

  categorisedPurchaseLinks.forEach((item: any) => {
    if (item.name === 'get_tickets') {
      if (siteSelection?.includes('in_theaters')) {
        buyLinks.push({
          name: t('getTicketsText', 'Get Tickets'),
          categories: [
            {
              links: item.links,
            },
          ],
        });
      }
    } else {
      const prefix = item.name === 'on_disc' ? 'onDisc' : item.name;
      categories.push({
        name: getTextByType({
          prefix,
          ...commonOpts,
        }),
        links: item.links,
      });
    }
  });

  if (categories.length > 0) {
    buyLinks.push({
      categories,
      name: getTextByType({
        prefix: 'buy',
        ...commonOpts,
      }),
    });
  }

  return buyLinks;
};

const getBody = (type: D7ContentType, description: string, customMarketingMessage: string): string => {
  switch (type) {
    case 'news_release':
    case 'page':
      return description;
    case 'movies':
    case 'tv_season':
    case 'tv_series':
    case 'video_game':
    case 'bio':
      return customMarketingMessage;
    default:
      return '';
  }
};

const getSearchDate = (date: string, locale: string, contentType: ContentType): string => {
  switch (contentType) {
    case 'Articles':
    case 'Press Releases':
      return formattedDate(date, locale);
    case 'Games & Apps':
    case 'Movies':
      return dateStringToYear(date);
    default:
      return '';
  }
};

export const normalizeSearchResults = (response: any, options: SearchOptions): NormalizedSearchResult[] => {
  const data = response?.data || {};
  const nodes = (options.type === SearchType.getNodes ? data.getNodes : data.query?.nodes) || [];

  return nodes.map((n: any) => {
    const {
      nid: id,
      truncatedTitle: title,
      type,
      truncatedDescription: description,
      customMarketingMessage,
      releaseDate,
      url,
      isMobileGameAndApp,
      thumbnails,
      categorisedPurchaseLinks,
      categories,
      siteSelection,
    } = n;
    const irsPreset = options?.irsPreset || getIrsPreset(type, isMobileGameAndApp);

    let image = null;
    if (thumbnails && thumbnails.length > 0) {
      image = {
        src: imageRenditionObj.getImageRenditionURL(thumbnails[0].nodeSrc, irsPreset),
        alt: thumbnails[0].alt || title,
      };
    }

    const locale = options.locale || 'en-US';
    const contentType = toContentType(type) || 'Movies';
    const date = getSearchDate(releaseDate, locale, contentType);

    const body = getBody(type, description, customMarketingMessage);
    const buyLinks = normalizePurchaseLinks(
      siteSelection,
      isMobileGameAndApp,
      categorisedPurchaseLinks || [],
      locale,
      contentType,
    );

    return {
      body,
      buyLinks,
      categories,
      contentType,
      date,
      id,
      image,
      title,
      url,
      isMobileGameOrApp: isMobileGameAndApp,
      marketingMessage: customMarketingMessage,
    };
  });
};

export const normalizeAutoSuggestResults = (response: any): NormalizedSearchResult[] => {
  const nodes = response?.data?.query?.nodes || [];

  return nodes.map((n: any) => {
    const { nid: id, type, title, thumbnails, url } = n;

    let image = null;
    if (thumbnails && thumbnails.length > 0) {
      const irsPreset = getIrsPreset(type, false);
      image = {
        src: imageRenditionObj.getImageRenditionURL(thumbnails[0].nodeSrc, irsPreset),
        alt: thumbnails[0].alt,
      };
    }

    const contentType = toContentType(type);

    return { id, title, contentType, url, image };
  });
};
