import { motion } from 'framer-motion';
import * as React from 'react';
import { NavListProps } from './NavList.props';
import { StyledNavList } from './NavList.styles';
import { NavListItem } from './NavListItem';
import { NavListItemProps } from './NavListItem.props';

const listVariants: any = {
  open: {
    width: 'auto',
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: 'backInOut',
      staggerChildren: 0.05,
    },
    pointerEvents: 'auto',
  },
  closed: {
    width: 0,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: 'easeOut',
      staggerChildren: 0.025,
      staggerDirection: -1,
    },
    pointerEvents: 'none',
  },
};

const listItemVariants: any = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: 'backInOut',
    },
  },
  closed: {
    opacity: 0,
    y: '-100%',
    transition: {
      duration: 0.3,
      ease: 'circOut',
    },
  },
};

export const NavList = ({ direction, navListItems, open }: NavListProps) => {
  if (!(navListItems || []).length) {
    return null;
  }

  return (
    <StyledNavList
      animate={open ? 'open' : 'closed'}
      data-component='nav-list'
      direction={direction}
      initial='open'
      variants={listVariants}
    >
      {navListItems.map((navListItemProps: NavListItemProps) => (
        <motion.li key={navListItemProps.url} variants={listItemVariants}>
          <NavListItem {...navListItemProps} fullWidth={direction === 'vertical'} tabIndex={open ? 0 : -1} />
        </motion.li>
      ))}
    </StyledNavList>
  );
};
