import * as qs from 'query-string';
import { SearchOptions, SearchResults, SearchResultsWithFacets } from '../common';
import { runAutoSuggest, runSearch, runSearchNodes } from './graphql';
import { normalizeAutoSuggestResults, normalizeFacets, normalizeSearchResults } from './normalizers';

interface SearchQuery {
  searchText: string;
  types: string[];
  page: string;
  size: string;
}

const getFirstParameter = (queryStringValue: string | string[] | undefined | null): string => {
  if (Array.isArray(queryStringValue)) {
    return queryStringValue[0] || '';
  }
  return queryStringValue || '';
};

const solrParamRegex = new RegExp('f\\[\\d\\]');
const parseQuery = (queryString: string): SearchQuery => {
  const queryParams = qs.parse(queryString);
  const searchText: string = (Array.isArray(queryParams.qt || '') ? (queryParams as any).qt[0] : queryParams.qt) || '';
  const types = Object.keys(queryParams)
    .filter((k) => solrParamRegex.test(k))
    .map((k) => getFirstParameter(queryParams[k]).replace('type:', ''));

  const page = getFirstParameter(queryParams.page).trim();
  const size = getFirstParameter(queryParams.size).trim();
  return { searchText, types, page, size };
};

export const getSearchTextResults = async (
  queryString: string,
  options: SearchOptions,
): Promise<SearchResultsWithFacets> => {
  const { searchText, types, page, size } = parseQuery(queryString);
  const data = await runSearch(searchText, types, page, size, options);
  const facets = normalizeFacets(data, searchText);
  const results = normalizeSearchResults(data, options);

  return { facets, results };
};

export const getSearchNodeResults = async (
  queryString: string,
  options: SearchOptions,
): Promise<SearchResultsWithFacets> => {
  const data = await runSearchNodes(queryString);
  const results = normalizeSearchResults(data, options);

  return { results, facets: [] };
};

export const getSearchServiceAutoSuggestResults = async (
  query: string,
  options: SearchOptions,
): Promise<SearchResults> => {
  const data = await runAutoSuggest(query, options);
  const results = normalizeAutoSuggestResults(data);

  return { results };
};
