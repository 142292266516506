import * as React from 'react';
import { Head, useRoutePath } from 'react-static';
import { useSiteRoot } from '../../common/hooks/useSiteRoot';
import { HeadMetaProps } from './HeadMeta.props';

export const HeadMeta = ({ locale, description }: HeadMetaProps) => {
  const currentPath = useRoutePath('');
  const siteRoot = useSiteRoot();

  const canonicalUrl = `${siteRoot}/${currentPath}`.replace(/\/+$/, '');

  return (
    <Head>
      {/* tslint:disable-next-line:react-a11y-lang */}
      {locale && <html lang={locale} />}
      <meta charSet='UTF-8' />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
      <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
      {description && <meta name='description' content={description} />}
      <link rel='icon' type='image/x-icon' href={'/assets/favicon.ico'} />
      <link rel='icon' type='image/png' sizes='16x16' href={'/assets/favicon-16x16.png'} />
      <link rel='icon' type='image/png' sizes='32x32' href={'/assets/favicon-32x32.png'} />
      <link rel='apple-touch-icon' sizes='180x180' href={'/assets/apple-touch-icon.png'} />
      <link rel='manifest' href={'/assets/manifest.json'} />
      <link rel='canonical' href={canonicalUrl} />
    </Head>
  );
};
