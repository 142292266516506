import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledNavListContainer = styled(motion.div)`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  overflow: hidden;
`;
