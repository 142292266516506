import { Button as MaterialUiButton, ButtonProps as MaterialUiButtonProps } from '@material-ui/core';
import { Link } from '@reach/router';
import * as React from 'react';
import { isAbsoluteUrl } from '../../../common/helpers/strings';
import { SuperButtonProps } from './SuperButton.props';
import { LoadingEllipsis } from './SuperButton.styles';

export const routerButtonValue = 'router-button';
export const routerLinkButtonClass = 'router-link-button';

export const SuperButton = ({
  className,
  disableCaps,
  fullWidthBreakpoint,
  href,
  invertColors,
  leftAlign,
  loading,
  size,
  ...buttonProps
}: SuperButtonProps &
  Pick<MaterialUiButtonProps, 'children' | 'classes' | 'disabled' | 'disableFocusRipple' | 'fullWidth'> &
  Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className' | 'onClick' | 'onKeyDown'> &
  Pick<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'aria-label' | 'href' | 'target' | 'title'>) => {
  if (loading) {
    buttonProps.children = (
      <>
        {buttonProps.children}
        <LoadingEllipsis />
      </>
    );
    buttonProps.onClick = () => undefined;
  }

  return href && !isAbsoluteUrl(href) ? (
    <Link className={`${className} ${routerLinkButtonClass}`} to={href}>
      <MaterialUiButton data-type={routerButtonValue} disableFocusRipple={true} tabIndex={-1} {...buttonProps} />
    </Link>
  ) : (
    <MaterialUiButton className={className} disableFocusRipple={true} href={href} {...buttonProps} />
  );
};
