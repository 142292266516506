import axios from 'axios';
import * as Debug from 'debug';
import * as _ from 'lodash';
import { logToSentry } from '../../components/catch/Catch';
import { ASSETS_BASEURL, FILES_FOLDER_PATH } from '../../config/browser';
import { ImageRenditionPresets } from '../image-rendition';

const debug = Debug('app:d7:common');

export const VendorCategoryRegex = {
  STREAM: /stream/i,
  DIGITAL: /online/i,
  ON_DISC: /disc/i,
  IN_THEATERS: /ticket/i,
};

export const getLastBuildTimeStamp = async (): Promise<string> =>
  axios
    .get('/LAST_BUILD')
    .then((res) => {
      debug('LAST_BUILD response: ', res.data);
      return res.data.replace(/\s/g, '');
    })
    .catch((error) => {
      debug('LAST_BUILD error response: ', error.message);
      logToSentry(error);
      return 'none';
    });

const GamesAndAppsTypes = {
  MOBILE_APP: 'mobileapp',
  MOBILE_GAME: 'mobilegame',
  VIDEO_GAME: 'videogame',
};

export const checkMobileGameOrApp = (type: string | string[] | undefined): boolean => {
  const val = Array.isArray(type) ? type : [type];
  return [GamesAndAppsTypes.MOBILE_GAME, GamesAndAppsTypes.MOBILE_APP].some((ele) => val.includes(ele));
};

export type D7ContentType =
  | 'article'
  | 'brands'
  | 'collections'
  | 'divisions'
  | 'bio'
  | 'video_game'
  | 'page'
  | 'movies'
  | 'news_release'
  | 'tv_season'
  | 'tv_series';

const contentTypeMap: {
  [k in ContentType]: D7ContentType;
} = {
  Articles: 'article',
  Brands: 'brands',
  Collections: 'collections',
  Divisions: 'divisions',
  Executives: 'bio',
  'Games & Apps': 'video_game',
  'Informational Pages': 'page',
  Movies: 'movies',
  'Press Releases': 'news_release',
  'TV Seasons': 'tv_season',
  'TV Series': 'tv_series',
};
export const d7TypeMap = _.invert(contentTypeMap);

export const toContentType = (type: string): ContentType | undefined =>
  d7TypeMap[(type || '').toLowerCase().replace(' ', '_')] as ContentType;

export const d7URIToUrl = (uri: string): string =>
  (uri || '').replace('public://', `${ASSETS_BASEURL}${FILES_FOLDER_PATH}/`);

export interface BuyLink {
  image: Image;
  url: string;
}

export interface BuyLinkCategory {
  links: BuyLink[];
  name?: string;
}

export interface BuyLinkGroup {
  categories: BuyLinkCategory[];
  name: string;
}

export interface NormalizedSearchResult {
  body: string | string[];
  buyLinks: BuyLinkGroup[];
  categories: string;
  contentType: ContentType;
  date: string;
  id: string;
  image?: Image;
  isMobileGameOrApp?: boolean;
  marketingMessage: string;
  title: string;
  url: string;
}

export interface NormalizedFacet {
  numResults: number;
  query: string;
  text: string;
  type: string;
}

export type OrderField = 'releaseDate' | 'title';

export interface SearchOptions {
  irsPreset?: ImageRenditionPresets;
  locale?: string;
  orderField?: OrderField;
  order?: 'desc' | 'asc';
  filterInfo?: {
    field: string;
    value: any;
    operation?: 'contains' | 'not contains';
  };
  type?: SearchType;
}

export interface SearchResults {
  results: NormalizedSearchResult[];
}

export interface SearchResultsWithFacets extends SearchResults {
  facets: NormalizedFacet[];
}

export enum SearchType {
  getNodes = 'get_nodes',
  searchText = 'search_text',
}

export enum TitleLayoutType {
  DEFAULT = 'default',
  MOVIE = 'movie',
  VIDEOGAME = 'videogame',
}
