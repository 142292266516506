import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { white } from '../../common/colors';
import { totalNavigationPxHeight } from '../navigation/Navigation.styles';

export const StyledBrowserNotice = styled.section`
  display: block;
  position: absolute;
  margin: 0 auto;
  top: ${rem(totalNavigationPxHeight)};
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.92;
  z-index: 99;
  background-color: ${white};
  width: 50%;
  height: 150px;
  padding: 1.5rem;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);

  ${mediaQueryWidth({ max: 'tablet' })`
    width: 90%;
    height: 150px;
    display: none;
  `};
`;

export const StyledBNCloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 16px;
  width: 16px;
  cursor: pointer;
`;

export const StyledBNHeader = styled.header`
  margin-bottom: 0;
  margin-top: 0;
  position: relative;

  & > h2 {
    margin: 0;
    padding: 0;
    font-size: ${rem(16)};
    font-weight: 800;
    line-height: ${rem(19)};
  }
`;

export const StyledBNMessage = styled.p`
  font-size: 14px;
  margin: 10px 25px 10px 0;
  line-height: 18px;
`;

export const StyledBNLogoList = styled.ul`
  display: flex;
  justify-content: flex-start;

  ${mediaQueryWidth({ max: 'tablet' })`
    justify-content: space-evenly;
  `};
`;
