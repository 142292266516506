import * as React from 'react';
import GlobeIcon from '../../../assets/images/icons/svg/globe.svg';
import { useLocale } from '../../../common/hooks/useLocale';
import { LOCALE_CONFIG } from '../../../common/translation';
import { SuperLink } from '../../super-link/SuperLink';
import { LanguageSwitcherProps, TranslatedPath } from './LanguageSwitcher.props';
import { StyledLanguageSwitcher, StyledLSOption } from './LanguageSwitcher.styles';

interface LocaleTranslation {
  absentText: string;
  presentText: string;
}

const localeTranslations: { [locale: string]: LocaleTranslation } = {
  'en-CA': {
    absentText: 'English not available',
    presentText: 'English',
  },
  'fr-CA': {
    absentText: 'Français non offert',
    presentText: 'Français',
  },
  'nl-BE': {
    absentText: 'Nederlands niet beschikbaar',
    presentText: 'Nederlands',
  },
  'fr-BE': {
    absentText: 'Français non offert',
    presentText: 'Français',
  },
  'de-CH': {
    absentText: 'Deutsch nicht verfügbar',
    presentText: 'Deutsch',
  },
  'fr-CH': {
    absentText: 'Français non disponible',
    presentText: 'Français',
  },
};

const languageForLocale = (locale: string, isPresent: boolean): string => {
  const translation = localeTranslations[locale];
  if (!translation) {
    throw new Error(`Invalid locale for language switching: ${locale}`);
  }

  return isPresent ? translation.presentText : translation.absentText;
};

const buildMenu = (currentLocale: string, translatedPaths: TranslatedPath[]) => {
  const menu: React.ReactNode[] = [];

  LOCALE_CONFIG.forEach(({ name: locale }) => {
    if (locale === currentLocale) {
      menu.push(
        <StyledLSOption current={true} key={locale}>
          {languageForLocale(locale, true)}
        </StyledLSOption>,
      );
    } else {
      const match = translatedPaths.filter((tp) => tp.locale === locale)[0];
      const path = (match && match.path) || '';
      const option = path ? (
        <SuperLink to={path}>{languageForLocale(locale, true)}</SuperLink>
      ) : (
        languageForLocale(locale, false)
      );

      menu.push(
        <StyledLSOption current={false} key={locale}>
          {option}
        </StyledLSOption>,
      );
    }
  });

  return menu;
};

export const LanguageSwitcher = ({ translatedPaths = [] }: LanguageSwitcherProps) => {
  const currentLocale = useLocale();
  const menu = buildMenu(currentLocale, translatedPaths);

  return (
    <StyledLanguageSwitcher>
      <GlobeIcon />
      {menu}
    </StyledLanguageSwitcher>
  );
};
