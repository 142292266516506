import * as React from 'react';
import brandsIcon from '../../assets/images/icons/svg/brands.svg';
import collectionsIcon from '../../assets/images/icons/svg/collections.svg';
import gamesAndAppsIcon from '../../assets/images/icons/svg/games-and-apps.svg';
import gridViewIcon from '../../assets/images/icons/svg/grid-view.svg';
import hboMaxIcon from '../../assets/images/icons/svg/hbo-max.svg';
import infoPageIcon from '../../assets/images/icons/svg/info-page.svg';
import moviesIcon from '../../assets/images/icons/svg/movies.svg';
import articlesIcon from '../../assets/images/icons/svg/news.svg';
import shopIcon from '../../assets/images/icons/svg/shop.svg';
import experiencesIcon from '../../assets/images/icons/svg/studiotour.svg';
import tvIcon from '../../assets/images/icons/svg/tv-shows.svg';
import pressReleasesIcon from '../../assets/images/icons/svg/water-tower.svg';
import wbShieldIcon from '../../assets/images/icons/svg/wb-shield.svg';

export const contentTypeToIcon: { [key in ContentType]: React.FunctionComponent } = {
  Articles: articlesIcon,
  Brands: brandsIcon,
  Collections: collectionsIcon,
  Divisions: wbShieldIcon,
  Executives: wbShieldIcon,
  'Informational Pages': infoPageIcon,
  'Games & Apps': gamesAndAppsIcon,
  Movies: moviesIcon,
  'Press Releases': pressReleasesIcon,
  'TV Seasons': tvIcon,
  'TV Series': tvIcon,
};

interface NavigationIcons {
  all: string;
  article: string;
  bio: string;
  brands: string;
  collections: string;
  experiences: string;
  hbo_max: string;
  movies: string;
  news_release: string;
  page: string;
  shop: string;
  tv_series: string;
  video_game: string;
  [key: string]: string;
}

export const NavigationIcons: NavigationIcons = {
  all: gridViewIcon,
  article: articlesIcon,
  bio: wbShieldIcon,
  brands: brandsIcon,
  collections: collectionsIcon,
  experiences: experiencesIcon,
  hbo_max: hboMaxIcon,
  movies: moviesIcon,
  news_release: pressReleasesIcon,
  page: infoPageIcon,
  shop: shopIcon,
  tv_series: tvIcon,
  video_game: gamesAndAppsIcon,
};
