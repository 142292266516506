import { rem } from 'polished';
import styled from 'styled-components';
import { borderlessFocusStyles } from '../../../common/state.styles';

export const logoWidthPx = 68;

export const StyledLogo = styled.div`
  width: ${rem(logoWidthPx)};

  & > a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    ${borderlessFocusStyles}
  }
`;
