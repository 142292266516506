import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { black } from '../../common/colors';

export const StyledBrowserLogo = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 40px;
  text-align: center;
  position: relative;

  ${mediaQueryWidth({ max: 'tablet' })`
    margin-right: 20px;
  `};

  & > a {
    text-decoration: none;
    color: ${black};

    & > svg {
      width: 50px;
      height: 50px;
      background-color: transparent;
    }
  }
}`;

export const StyledBrowserLogoName = styled.div`
  font-size: 12px;
  line-height: normal;
`;
