import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { IS_SERVER } from '../constants';

interface ScrollState {
  scrollX: number;
  scrollY: number;
}

const getScrollState = (): ScrollState => {
  if (IS_SERVER) {
    return {
      scrollX: 0,
      scrollY: 0,
    };
  }

  return {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
};

export const useWindowScroll = (debounceTime: number = 0) => {
  const [scrollState, setScrollState] = useState<ScrollState>(getScrollState());

  const debouncedHandleScroll = debounce(() => {
    setScrollState(getScrollState());
  }, debounceTime);

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
      debouncedHandleScroll.cancel();
    };
  }, []);

  return scrollState;
};
