import { rem } from 'polished';
import styled from 'styled-components';
import { hoverMediaQuery, mediaQueryWidth, noHoverMediaQuery } from '../../common/breakpoints';
import { darkGrey1, darkGrey2, grey1, grey40, lightGrey2, white } from '../../common/colors';

export const FooterCopyrightContainer = styled.div<{ collapseCopyright: boolean }>`
  display: inline-block;
  width: ${({ collapseCopyright }) => (collapseCopyright ? '38%' : '50%')};
  padding-top: ${({ collapseCopyright }) => (collapseCopyright ? rem(16) : rem(8))};
  padding-bottom: ${({ collapseCopyright }) => (collapseCopyright ? rem(16) : rem(8))};
  padding-left: ${rem(24)};
  box-sizing: border-box;

  ${mediaQueryWidth({ max: 'tablet' })`
    order: 1;
    width: 100%;
    padding: ${rem(8)} ${rem(24)};
    background-color: ${darkGrey1};
  `}

  @media print {
    display: block;
    width: 100%;
  }
`;

export const FooterCopyright = styled.div`
  display: inline-block;
  width: calc(100% - ${rem(50)});
  margin: 0 0 0 ${rem(14)};
  padding: 0 0 0 0;
  box-sizing: border-box;
  vertical-align: middle;
  line-height: 1.625em;

  ${mediaQueryWidth({ max: 399 })`
    font-size: ${rem(8)};
  }`}

  ${mediaQueryWidth({ min: 400, max: 499 })`
    font-size: ${rem(9)};
  }`}

  ${mediaQueryWidth({ min: 500, max: 599 })`
    font-size: ${rem(12)};
  `}
`;

export const FooterSocialCollectionContainer = styled.div`
  display: block;
  margin: ${rem(10)} ${rem(20)} ${rem(10)} 0;
  padding: 0 ${rem(20)} 0 0;
  border-right: 1px solid ${grey1};
  border-right: 1px solid rgba(255, 255, 255, 0.1);

  &:last-of-type {
    border: 0;
  }

  ${mediaQueryWidth({ max: 'tablet' })`
    border: 0;
    margin-right: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0 0 ${rem(16)} 0;
    div.collection-name {
      text-align: center;
    }

    &:last-of-type {
      padding-bottom: ${rem(10)};
    }
  `}
`;

export const FooterSocialLinksContainer = styled.div<{ collapseCopyright: boolean }>`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  width: ${({ collapseCopyright }) => (collapseCopyright ? '62%' : '50%')};
  padding: 0 ${rem(24)} 0 0;
  box-sizing: border-box;

  ${mediaQueryWidth({ max: 'tablet' })`
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: ${rem(12)} ${rem(24)} 0 ${rem(24)};
  `}

  @media print {
    display: none;
  }
`;

export const FooterSocialLinksTitle = styled.div`
  font-size: ${rem(16)};

  ${mediaQueryWidth({ max: 'tablet' })`
    font-size: ${rem(14)};
    color: ${lightGrey2};
  `}
`;

export const FooterSocialLinks = styled.nav`
  display: flex;
  flex-direction: row;
  padding: 0 0 0 ${rem(16)};

  ${mediaQueryWidth({ max: 'tablet' })`
    justify-content: space-around;
    height: ${rem(20)};
    width: 100%;
    margin: ${rem(12)} 0 0 0;
    padding: 0;
  `}

  a {
    position: relative;
    width: ${rem(24)};

    ${mediaQueryWidth({ min: 'tablet' })`
      &:not(:first-of-type) {
        margin-left: ${rem(8)};
      }

      &:not(:last-of-type) {
        margin-right: ${rem(8)};
      }
    `}

    svg {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%) scale(1);
      height: ${rem(18)};
      width: inherit;
      fill: ${white};

      &.pinterest-icon {
        height: ${rem(22)};
      }

      &.linkedin-icon {
        height: ${rem(20)};
        margin-top: -${rem(2)}; // Adjust for off-center SVG
      }

      &.youtube-icon {
        height: ${rem(23)};
      }

      &.newsletter-icon {
        height: ${rem(20)};
        margin-top: -${rem(1)}; // Adjust for off-center SVG
      }

      ${hoverMediaQuery} {
        :hover {
          transform: translate(0, -50%) scale(1.25);
          transition: transform 0.5s ease-out;
        }

        &.twitter-icon:hover {
          fill: #38a1f3;
        }

        &.facebook-icon:hover {
          fill: #1877f2;
        }

        &.instagram-icon:hover {
          fill: #bc2a8d;
        }

        &.pinterest-icon:hover {
          fill: #e60023;
        }

        &.linkedin-icon:hover {
          fill: #00a0dc;
        }

        &.youtube-icon:hover {
          fill: #ff0000;
        }

        &.newsletter-icon:hover {
          fill: #3b5998;
        }
      }

      ${noHoverMediaQuery} {
        &.twitter-icon:active {
          fill: #38a1f3;
        }

        &.facebook-icon:active {
          fill: #1877f2;
        }

        &.instagram-icon:active {
          fill: #bc2a8d;
        }

        &.pinterest-icon:active {
          fill: #e60023;
        }

        &.linkedin-icon:active {
          fill: #00a0dc;
        }

        &.youtube-icon:active {
          fill: #ff0000;
        }

        &.newsletter-icon:active {
          fill: #3b5998;
        }
      }
    }
  }
`;

export const FooterSocialCollectionLinks = styled(FooterSocialLinks as any)`
  padding: 0;
  margin: 0;
  height: ${rem(32)};

  ${mediaQueryWidth({ max: 'tablet' })`
    min-width: ${rem(200)};
    justify-content: center;

    a {
      &:not(:first-of-type) {
        margin-left: ${rem(8)};
      }

      &:not(:last-of-type) {
        margin-right: ${rem(8)};
      }
    }
  `}
`;

export const FooterWBPolicyContainer = styled.div`
  width: 100%;
  padding: ${rem(8)} 0 ${rem(12)} 0;

  a {
    line-height: ${rem(16)};
    color: ${white};
    white-space: normal;
    cursor: pointer;

    span {
      margin-left: ${rem(4)};
      color: ${grey40};
    }
  }

  ${mediaQueryWidth({ max: 'tablet' })`
    a {
      display: block;
      padding: ${rem(8)} 0;
    }
  `}

  ${mediaQueryWidth({ min: 'tablet' })`
    display: flex;
    justify-content: space-between;
    padding: ${rem(8)} 0;
    background-color: ${darkGrey1};

    a {
      display: inline-block;
    }
  `}
`;

export const FooterWBLinksContainer = styled.nav`
  display: inline-block;
  width: 50%;
  box-sizing: border-box;
  padding: 0 0 0 ${rem(24)};
  text-align: left;
  vertical-align: top;

  ${mediaQueryWidth({ max: 'tablet' })`
    padding-right: ${rem(16)};
  `}

  ${mediaQueryWidth({ min: 'tablet' })`
    width: auto;

    a {
      white-space: nowrap;
      padding-right: ${rem(16)};
    }
  `}

  @media print {
    display: none;
  }
`;

export const FooterPolicyLinksContainer = styled.nav`
  display: inline-block;
  width: 50%;
  box-sizing: border-box;
  padding: 0 ${rem(24)} 0 0;
  text-align: right;
  vertical-align: top;

  ${mediaQueryWidth({ max: 'tablet' })`
    border-left: 1px solid ${grey1};
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    padding-left: ${rem(16)};
    text-align: left;
  `}

  ${mediaQueryWidth({ min: 'tablet' })`
    width: auto;

    a {
      white-space: nowrap;

      &:not(:first-of-type) {
        padding-left: ${rem(16)};
      }
    }
  `}

  @media print {
    display: none;
  }
`;

export const StyledFooter = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: ${rem(13)};
  color: ${white};
  background-color: ${darkGrey2};
  -webkit-print-color-adjust: exact;
  clear: both;
`;
