import { navigate } from '@reach/router';
import { useEffect } from 'react';
import { isMultiCountry } from '../../common/countries';
import {
  getCountryCodeFromPath,
  getCurrentCountryCode,
  setCurrentCountryCode,
} from '../../common/helpers/countrySelection';
import { DEFAULT_LANGUAGE } from '../../common/translation';

export const CountryChecker = () => {
  useEffect(() => {
    if (isMultiCountry()) {
      const pathCountryCode = getCountryCodeFromPath();
      if (pathCountryCode) {
        setCurrentCountryCode(pathCountryCode);
      } else {
        const savedCountryCode = getCurrentCountryCode();
        if (savedCountryCode) {
          const location = window.location;
          const currentPath = `${location.pathname}${location.search}${location.hash}`;
          navigate(`/${DEFAULT_LANGUAGE}-${savedCountryCode.toLowerCase()}${currentPath}`, { replace: true });
        } else {
          navigate('/', { replace: true });
        }
      }
    }
  }, []);

  return null;
};
