import * as Debug from 'debug';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useRoutePath } from 'react-static';
import { normalizeUrl } from '../../common/helpers/strings';
import { useSiteRoot } from '../../common/hooks/useSiteRoot';
import { HeadHrefLangProps } from './HeadHrefLang.props';
import { hrefLangService, LocaleUrl } from './HrefLangService';

const debug = Debug('app:cmp:HeadHrefLang');

export const HeadHreflang = ({ data }: HeadHrefLangProps) => {
  const currentPath = useRoutePath('');
  const siteRoot = useSiteRoot();

  const currentUrl = normalizeUrl(`${siteRoot}/${currentPath}`);
  const localizedHrefsArray = hrefLangService.getOtherLocaleUrls(currentUrl, data);
  debug('localizedHrefsArray: ', localizedHrefsArray);
  if (!localizedHrefsArray.length) {
    return null;
  }

  return (
    <Helmet>
      {localizedHrefsArray.map(({ url, locale }: LocaleUrl) => (
        <link rel='alternate' href={url} hrefLang={locale} key={`${locale}-${url}`} />
      ))}
    </Helmet>
  );
};
