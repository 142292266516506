import { MARKET } from '../../config/browser';
import { isMultiCountry, isValidCountryCode } from '../countries';
import { DEFAULT_LANGUAGE } from '../translation';
import { hasLocalStorage } from './window';

const countrySelectionKey = `country_selection_${MARKET}`;

export const getCurrentCountryCode = (): string | undefined => {
  if (hasLocalStorage()) {
    const countryCode = localStorage.getItem(countrySelectionKey);
    if (isValidCountryCode(countryCode)) {
      return countryCode as string;
    }
  }
};

export const setCurrentCountryCode = (countryCode: string): void => {
  if (hasLocalStorage() && isValidCountryCode(countryCode)) {
    localStorage.setItem(countrySelectionKey, countryCode);
  }
};

export const clearCountryCode = (): void => {
  if (hasLocalStorage()) {
    localStorage.removeItem(countrySelectionKey);
  }
};

export const getCountryPrefixFromPath = (): string | undefined => {
  if (typeof window !== 'undefined' && isMultiCountry()) {
    const matched = window.location.pathname.match(/^\/([a-z]{2})-([a-z]{2})(?:$|\/)/i);
    if (matched && matched[1].toLowerCase() === DEFAULT_LANGUAGE && isValidCountryCode(matched[2])) {
      return `/${matched[1].toLowerCase()}-${matched[2].toLowerCase()}`;
    }
  }
};

export const getCountryCodeFromPath = (): string | undefined => {
  const prefix = getCountryPrefixFromPath();
  if (!prefix) {
    return;
  }

  const parts = prefix.split('-');
  if (parts.length === 2) {
    return parts[1].toUpperCase();
  }
};

export const stripCountryPrefixFromPath = (path: string) => {
  if (!isMultiCountry()) {
    return path;
  }

  return path.replace(/^\/([a-z]{2})-([a-z]{2})($|\/)/i, (str, p1, p2, p3) => {
    if (p1.toLowerCase() === DEFAULT_LANGUAGE && isValidCountryCode(p2)) {
      return p3;
    }

    return str;
  });
};
export const getCountryOrMarketCode = (): string | undefined => {
  return isMultiCountry() ? getCountryCodeFromPath() : MARKET;
};
