import { Link } from '@reach/router';
import * as React from 'react';
import { updatePathWithPrefix } from '../../../common/helpers/prefix';
import { WhiteShield } from '../../white-shield/WhiteShield';
import { StyledLogo } from './Logo.styles';

export const Logo = () => {
  const rootPath = updatePathWithPrefix('/');

  return (
    <StyledLogo>
      <Link to={rootPath}>
        <WhiteShield showFlag={true} />
      </Link>
    </StyledLogo>
  );
};
