import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { globalHistory, navigate } from '@reach/router';
import classnames from 'classnames';
import { debounce } from 'lodash';
import * as qs from 'query-string';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import CloseIcon from '../../../assets/images/icons/svg/close.svg';
import SearchIcon from '../../../assets/images/icons/svg/search.svg';
import { Routes } from '../../../common/constants';
import { NormalizedSearchResult } from '../../../common/d7/common';
import { getAutoSuggestResults } from '../../../common/d7/search';
import { getPrefixRedirectedUrl } from '../../../common/helpers/prefix';
import { useTranslations } from '../../../common/translation';
import { Ripple } from '../../ripple/Ripple';
import { SearchDropdownCard } from '../../search-results/search-dropdown-card/SearchDropdownCard';
import translations from '../translations/navigation';
import { NavSearchProps } from './NavSearch.props';
import {
  AnimatedSection,
  AutoSuggest,
  NavSearchButton,
  Results,
  StyledNavSearch,
  StyledNoResults,
  StyledResultsContainer,
  StyledSearchButton,
  StyledSearchIconContainer,
} from './NavSearch.styles';

const fetchData = debounce(
  (
    text: string,
    locale: string,
    setSearchItems: (r: NormalizedSearchResult[]) => void,
    setSearching: (b: boolean) => void,
  ) => {
    (async () => {
      const response = await getAutoSuggestResults(text, { locale });
      setSearchItems(response.results);
      setSearching(false);
    })();
  },
  500,
);

export const NavSearch = ({ onSearchOpen, truncateWidth }: NavSearchProps) => {
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [searchItems, setSearchItems] = useState<NormalizedSearchResult[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { locale, t } = useTranslations(translations);

  const baseSearchUrl = getPrefixRedirectedUrl(Routes.search);
  const getSearchHref = (): string => `${baseSearchUrl}?${qs.stringify({ qt: searchText.trim() })}`;

  const handleSearchChange = (text: string) => {
    const trimmedText = text.trim();

    setSearchText(text);
    if (trimmedText.length >= 2) {
      setSearching(true);
      fetchData(trimmedText, locale, setSearchItems, setSearching);
    } else {
      setSearchItems([]);
    }
  };

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  };

  const resetSearch = () => {
    setSearchOpen(false);
    setSearching(false);
    setSearchText('');
    setSearchItems([]);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchChange(e.target.value);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && searchItems.length) {
      setSearchOpen(false);
      navigate(getSearchHref());
    } else if (['Escape', 'Esc'].includes(e.key)) {
      setSearchOpen(false);
    }
  };

  const onClickAway = () => {
    if (searchOpen) {
      setSearchOpen(false);
    }
  };

  useEffect(() => {
    if (onSearchOpen) {
      onSearchOpen(searchOpen);
    }

    if (searchOpen) {
      searchInputRef.current!.focus();
    } else {
      resetSearch();
    }
  }, [searchOpen]);

  useEffect(() => {
    const removeListener = globalHistory.listen(() => setSearchOpen(false));

    return () => {
      fetchData.cancel();
      removeListener();
    };
  }, []);

  const autosuggestOpen = searchItems.length > 0 || (!searching && searchText.length > 2 && searchItems.length === 0);
  const autoSuggestClasses = classnames({
    open: autosuggestOpen,
  });

  return (
    <ClickAwayListener onClickAway={onClickAway} touchEvent={false}>
      <StyledNavSearch className='nav-search'>
        <AnimatedSection className={classnames({ open: searchOpen })} truncateWidth={truncateWidth}>
          <StyledSearchIconContainer>
            <SearchIcon />
          </StyledSearchIconContainer>
          <input
            type='text'
            title={t('searchInputTitle', 'Type search term here')}
            value={searchText}
            onChange={onChange}
            onKeyDown={onKeyDown}
            aria-label={t('searchInputPlaceholder', 'Search')}
            placeholder={t('searchInputPlaceholder', 'Search')}
            ref={searchInputRef}
            className='search-input'
            tabIndex={searchOpen ? 0 : -1}
          />
          <AutoSuggest className={autoSuggestClasses}>
            <StyledResultsContainer data-testid='results-container'>
              {searchItems.length === 0 ? (
                <StyledNoResults>{t('searchNoResults', 'No Results Found')}</StyledNoResults>
              ) : (
                <>
                  <Results onClick={toggleSearch}>
                    {searchItems.map((searchResult: NormalizedSearchResult, index: number) => (
                      <SearchDropdownCard key={index} searchResult={searchResult} />
                    ))}
                  </Results>
                  <NavSearchButton href={getSearchHref()} onClick={toggleSearch}>
                    {t('searchAllResults', 'See All Results')}
                  </NavSearchButton>
                </>
              )}
            </StyledResultsContainer>
          </AutoSuggest>
        </AnimatedSection>
        {searching ? (
          <div className='ripple-wrapper'>
            <Ripple className='ripple-spinner' />
          </div>
        ) : (
          <StyledSearchButton
            aria-label={t('ariaSearchButton', 'Search button')}
            data-component='search-button'
            onClick={toggleSearch}
          >
            {searchOpen ? <CloseIcon /> : <SearchIcon />}
          </StyledSearchButton>
        )}
      </StyledNavSearch>
    </ClickAwayListener>
  );
};
