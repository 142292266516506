import * as React from 'react';
import WbCanadaShieldIcon from '../../assets/images/icons/svg/shields/wb-shield-ca.svg';
import WbShieldIcon from '../../assets/images/icons/svg/shields/wb-shield-no-flag.svg';
import { MARKET } from '../../config/browser';
import { WhiteShieldProps } from './WhiteShield.props';
import { WhiteShieldContainer } from './WhiteShield.styles';

const marketFlags = {
  CA: WbCanadaShieldIcon,
};
const shieldHasFlag = marketFlags.hasOwnProperty(MARKET);
const determineShield = (showFlag: boolean = false) => (showFlag && shieldHasFlag ? marketFlags[MARKET] : WbShieldIcon);

export const WhiteShield = ({ showFlag = false }: WhiteShieldProps) => {
  const Shield = determineShield(showFlag);

  return (
    <WhiteShieldContainer hasFlag={showFlag && shieldHasFlag}>
      <Shield />
    </WhiteShieldContainer>
  );
};
