import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { isMobile as isMobileUserAgent } from 'react-device-detect';
import { widthBreakpoints } from '../breakpoints';
import { IS_SERVER } from '../constants';
import { determineScreenType, UiScreenType } from '../helpers/window';

interface BrowserState {
  isMobileUserAgent: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isSmallScreen: boolean;
  isPrimaryNavCollapsed: boolean;
  isSmallDesktop: boolean;
  uiScreenType: UiScreenType;
  windowWidth: number;
}

const getBrowserState = (): BrowserState => {
  if (IS_SERVER) {
    return {
      isMobileUserAgent: true,
      isMobile: true,
      isTablet: false,
      isDesktop: false,
      isSmallScreen: false,
      isPrimaryNavCollapsed: true,
      isSmallDesktop: false,
      uiScreenType: UiScreenType.Mobile,
      windowWidth: 0,
    };
  }

  return {
    isMobileUserAgent,
    isMobile: window.innerWidth < widthBreakpoints.mobile,
    isTablet: window.innerWidth >= widthBreakpoints.mobile && window.innerWidth < widthBreakpoints.tablet,
    isDesktop: window.innerWidth >= widthBreakpoints.tablet,
    isSmallScreen: window.innerWidth < widthBreakpoints.tablet,
    isPrimaryNavCollapsed: window.innerWidth < widthBreakpoints.primaryNavCollapsed,
    isSmallDesktop: window.innerWidth >= widthBreakpoints.tablet && window.innerWidth < widthBreakpoints.desktop,
    uiScreenType: determineScreenType(),
    windowWidth: window.innerWidth,
  };
};

export const useWindowResize = () => {
  const [browserState, setBrowserState] = useState<BrowserState>(getBrowserState());

  const debouncedHandleResize = debounce(() => {
    setBrowserState(getBrowserState());
  }, 250);

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
      debouncedHandleResize.cancel();
    };
  }, []);

  return browserState;
};
