import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { darkGrey1 } from '../../common/colors';
import { SHOW_LANGUAGE_SWITCHER } from '../../common/translation';

export const navigationPxHeight = 55;
export const langSwitcherPxHeight = 30;
export const totalNavigationPxHeight = SHOW_LANGUAGE_SWITCHER
  ? navigationPxHeight + langSwitcherPxHeight
  : navigationPxHeight;

export const StyledNavigation = styled.div<{ consent: boolean }>`
  ${({ consent }) =>
    consent
      ? css`
          position: fixed;
        `
      : css`
          position: relative;
          margin-bottom: ${rem(-navigationPxHeight)};
        `}
  width: 100%;
  opacity: 0.99;
  color: white;
  background-color: ${darkGrey1};
  z-index: 999;

  @media print {
    display: none;
  }
`;

export const StyledInnerNav = styled.nav`
  display: flex;
  position: relative;
  height: ${rem(navigationPxHeight)};
`;
