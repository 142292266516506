import { DOMAIN_NAME } from '../../config/browser';
import { IS_SERVER } from '../constants';

export const useSiteRoot: () => string = () => {
  if (IS_SERVER) {
    return `${DOMAIN_NAME.startsWith('localhost') ? 'http' : 'https'}://${DOMAIN_NAME}`;
  }
  const { protocol, host } = window.location;
  return `${protocol}//${host}`;
};
