import { SearchOptions, SearchResults, SearchResultsWithFacets, SearchType } from './common';
import { getSearchNodeResults, getSearchServiceAutoSuggestResults, getSearchTextResults } from './search_service';

export async function getSearchResults(query: string, options: SearchOptions): Promise<SearchResultsWithFacets> {
  if (!query) {
    return { facets: [], results: [] };
  }

  return options.type === SearchType.getNodes
    ? getSearchNodeResults(query, options)
    : getSearchTextResults(query, options);
}

export async function getAutoSuggestResults(query: string, options: SearchOptions): Promise<SearchResults> {
  if (!query) {
    return { results: [] };
  }

  return getSearchServiceAutoSuggestResults(query, options);
}
