import * as Debug from 'debug';
import { useEffect } from 'react';

export const useDebug = (
  name: string,
  { other, props, state }: { other?: any; props?: any; state?: any } = {},
  deps = [],
) => {
  useEffect(() => {
    const fields = [
      { data: props, name: 'props' },
      { data: state, name: 'state' },
      { data: other, name: 'other' },
    ].filter((field) => field.data);
    const debugStr = fields.map((field) => `${field.name}: %O`).join(' ');
    const debugArgs = fields.map((field) => field.data);

    Debug(name)(debugStr, ...debugArgs);
  }, deps);
};
