import { Location } from '@reach/router';
import * as React from 'react';
import { useLayoutEffect, useState } from 'react';
import { Routes } from '../../common/constants';
import { useDebug } from '../../common/hooks/useDebug';
import { useWindowResize } from '../../common/hooks/useWindowResize';
import { SHOW_LANGUAGE_SWITCHER, useTranslations } from '../../common/translation';
import { DesktopNav } from './desktop-nav/DesktopNav';
import { LanguageSwitcher } from './language-switcher/LanguageSwitcher';
import { MobileNav } from './mobile-nav/MobileNav';
import { NavListItemProps } from './nav-list/NavListItem.props';
import { InnerNavProps, NavigationProps } from './Navigation.props';
import { StyledNavigation } from './Navigation.styles';
import translations from './translations/nav-list';

const defaultNavListItems: NavListItemProps[] = [
  {
    img: 'movies',
    title: 'Movies',
    url: Routes.movies,
  },
  {
    img: 'tv_series',
    title: 'TV Shows',
    url: Routes.tv,
  },
  {
    img: 'video_game',
    title: 'Games & Apps',
    url: Routes.gamesAndApps,
  },
];

export const Navigation = ({ translatedPaths }: NavigationProps) => {
  const { isPrimaryNavCollapsed } = useWindowResize();
  const { t } = useTranslations(translations);
  const [consent, setConsent] = useState(false);

  useDebug('app:cmp:Navigation', { state: { isPrimaryNavCollapsed } });

  const validateConsent = (obj: Window | any) => {
    if (!obj.Optanon) {
      setConsent(true);
      return;
    }
    const showAlertNotice = obj.Optanon.GetDomainData().ShowAlertNotice;
    const closed = obj.Optanon.IsAlertBoxClosed();
    setConsent(closed || !showAlertNotice);
  };

  const onConsentChange = (e: any) => {
    validateConsent(e.target);
  };

  useLayoutEffect(() => {
    validateConsent(window);
    window.addEventListener('consent.onetrust', onConsentChange);
    return () => {
      window.removeEventListener('consent.onetrust', onConsentChange);
    };
  }, []);

  return (
    <StyledNavigation consent={consent}>
      {SHOW_LANGUAGE_SWITCHER && <LanguageSwitcher translatedPaths={translatedPaths} />}
      <Location>
        {({ location }) => {
          const navProps: InnerNavProps = {
            location,
            navListItems: t('navListItems', defaultNavListItems),
          };
          const NavComponent = isPrimaryNavCollapsed ? MobileNav : DesktopNav;
          return <NavComponent {...navProps} />;
        }}
      </Location>
    </StyledNavigation>
  );
};
