import styled from 'styled-components';
import { darkGrey2, white } from '../../common/colors';

export const StyledSkipNavigation = styled.section`
  #skiptocontent {
    position: absolute;
    width: auto;
  }

  #skiptocontent a {
    padding: 0.375rem;
    position: absolute;
    top: -100px;
    white-space: nowrap;
    left: 0px;
    width: auto;
    color: ${white};
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    border-bottom-right-radius: 8px;
    background: ${darkGrey2};
    transition: top 1s ease-out;
    z-index: 100000;
  }

  #skiptocontent a:focus,
  #skip a:active {
    position: absolute;
    left: 0px;
    top: 0px;
    outline-color: transparent;
    transition: top 0.5s ease-in;
  }
`;
