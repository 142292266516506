import * as React from 'react';
import { SkipNavigationProps } from './SkipNavigation.props';
import { StyledSkipNavigation } from './SkipNavigation.styles';

export const SkipNavigation = ({ label, targetElement }: SkipNavigationProps) => (
  <StyledSkipNavigation>
    <div id='skiptocontent'>
      <a href={`#${targetElement}`}>{label}</a>
    </div>
  </StyledSkipNavigation>
);
