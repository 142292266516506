export const truncate = (content: string, limit: number, useWordBoundary: boolean = true): string => {
  if (content.length <= limit) {
    return content;
  }

  const subString: string = content.substr(0, limit - 1);
  return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString) + '...';
};

export const safeBGImageUrl = (url: string | undefined): string => (url ? `url("${encodeURI(url)}")` : '');

export const urlPath = (url: string): string => {
  // Base of https is used for protocol relative urls like //assets.www.warnerbros.com
  const myUrl = new URL(encodeURI(url), 'https://www.wb.com');
  const isDrupalPublicUrl: boolean = myUrl.protocol === 'public:';
  return isDrupalPublicUrl ? myUrl.href.substring(8) : myUrl.pathname;
};

export const toKey = (str: string): string =>
  str
    .toLowerCase()
    .replace(/[^a-z0-9]+/gi, '-')
    .replace(/^-|-$/, '');

export const isAbsoluteUrl = (url: string): boolean => /^(?:[\w\-]+:|\/\/)/i.test(url);

export const normalizeUrl = (url: string) => url.replace(/\/+$/, '').toLowerCase();
