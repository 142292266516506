import * as Debug from 'debug';
import { IS_SERVER } from '../../common/constants';
import { normalizeUrl } from '../../common/helpers/strings';

const debug = Debug('app:service:HrefLangService');

export interface LocaleUrl {
  locale: string;
  url: string;
}

export class HrefLangService {
  public static readonly spreadSheetOptions = {
    spreadsheetId: '1Jy659bO7be4aJ_3r-sxgm3CNlzNNPl9uGYtGPLs0wQ0',
    range: 'Sheet1',
  };

  public static async getGoogleData(GS: any): Promise<string[][]> {
    debug('getGoogleData()');
    if (IS_SERVER) {
      return (await GS.init()).readData(HrefLangService.spreadSheetOptions);
    }
    throw new Error('SSR method was called unexpectedly');
  }

  private hrefArray: LocaleUrl[][] = [[]];
  private markets: string[] = [];
  private locales: string[] = [];
  private initialized: boolean = false;

  constructor(rowData?: string[][]) {
    if (rowData) {
      this.convertDataToHrefMap(rowData);
      this.initialized = true;
    }
  }

  public convertDataToHrefMap(rowData: string[][]): void {
    debug('convertDataToHrefMap(): ', rowData);

    try {
      const data = JSON.parse(JSON.stringify(rowData));
      this.markets = data.shift() || [];
      this.locales = data.shift() || [];
      this.hrefArray = [];

      this.markets.shift();
      this.locales.shift();
      debug('markets: %O', this.markets);
      debug('locales: %O', this.locales);

      this.locales = this.locales.map((locale: string) => locale.toLowerCase());

      for (const urls of data) {
        urls.shift();
        const row: LocaleUrl[] = urls.map((url: string, i: number) => ({
          url: normalizeUrl(url),
          locale: this.locales[i].toLowerCase(),
        }));
        this.hrefArray.push(row);
      }

      this.initialized = true;
    } catch (err) {
      debug('Error: ', err);
      throw new Error(err);
    }
  }

  public getOtherLocaleUrls(rawHref: string, rowData?: string[][]): LocaleUrl[] {
    debug('getOtherLocaleUrls(): ', rawHref);

    if (!this.initialized && rowData) {
      this.convertDataToHrefMap(rowData);
    }

    const href = normalizeUrl(rawHref);
    const row: LocaleUrl[] | undefined = this.hrefArray.find((localeUrls) =>
      localeUrls.find(({ url }) => url === href),
    );

    return row || [];
  }
}

export const hrefLangService = new HrefLangService();
