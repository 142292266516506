import { rem } from 'polished';
import styled from 'styled-components';
import { black, darkGrey2, grey40, grey60, lightBlue, white } from '../../common/colors';

export const StyledLegalNoticeContainer = styled.div`
  border-top: 1px solid ${black};
  background-color: ${white};
  position: fixed;
  width: 100%;
  bottom: 0;
  display: none;
  z-index: 10;

  @media print {
    display: none !important;
  }
`;

export const StyledLegalNotice = styled.div`
  flex: 1;

  p {
    margin: 1rem;

    a {
      color: ${lightBlue};

      span {
        margin-left: ${rem(4)};
        color: ${grey40};
      }
    }

    span {
      margin-left: ${rem(4)};
      color: ${grey60};
    }
  }
`;

export const StyledLegalNoticeCloseButton = styled.div`
  margin: auto;
  padding: 0 1rem;

  // IE 11 isn't smart enough for the auto margin within flex box...
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-top: 1rem;
  }

  svg {
    height: 26px;
    width: 26px;
  }

  a {
    color: ${darkGrey2};

    :hover {
      cursor: pointer;
    }
  }
`;
