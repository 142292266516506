import { ASSETS_BASEURL, FILES_FOLDER_PATH, IMAGE_RENDITION_BASEURL } from '../../config/browser';
import { ImageRendition, ImageRenditionPresets } from './ImageRendition';

const imageRenditionObj = new ImageRendition(FILES_FOLDER_PATH, IMAGE_RENDITION_BASEURL, ASSETS_BASEURL);

export const imageRenditionURL = (url: string, preset: ImageRenditionPresets) =>
  imageRenditionObj.getImageRenditionURL(url, preset);

export const getImageRenditionRatio = (preset: ImageRenditionPresets) => {
  switch (preset) {
    case ImageRenditionPresets.MobileAppGamesSquare:
      return 1;
    case ImageRenditionPresets.ArticlePromo:
    case ImageRenditionPresets.Promo:
      return 1.25;
    case ImageRenditionPresets.Gallery:
    case ImageRenditionPresets.GalleryMobile:
    case ImageRenditionPresets.GalleryTablet:
      return 1.33;
    case ImageRenditionPresets.KeyArt:
      return 1.5;
    case ImageRenditionPresets.HeroBanner:
    case ImageRenditionPresets.HeroBannerMobile:
    case ImageRenditionPresets.HeroBannerTablet:
      return 4;
    default:
      return 1.5;
  }
};

export * from './ImageRendition';
