import { rem } from 'polished';
import styled from 'styled-components';
import { white } from '../../../common/colors';

export const hamburgerWidthPx = 45;

export const StyledHamburger = styled.button<{ open?: boolean }>`
  position: relative;
  display: block;
  width: ${rem(hamburgerWidthPx)};
  height: 100%;
  background: transparent;
  border: none;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    background-color: white;
    height: 2px;
    opacity: 1;
    left: ${rem(10)};
    right: ${rem(10)};
    transform: rotate(0deg);
    transition: all 0.6s cubic-bezier(0.25, 1.7, 0.35, 0.8);
    transition-property: opacity, transform;

    &:nth-child(1) {
      top: 30%;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 50%;
    }

    &:nth-child(4) {
      top: 69%; /*this needs to be 1% less when zoomed out*/
    }
  }

  ${({ open }) =>
    open &&
    `
      span {
        &:nth-child(1) {
          top: 50%;
          left: 50%;
          right: 50%;
          opacity: 0;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: 50%;
          left: 50%;
          right: 50%;
          opacity: 0;
        }
      }
    `}

  &:hover {
    span {
      box-shadow: 0 0 5px 0 rgba(255, 255, 255, 1);
    }
  }

  &:focus {
    outline: 1px dotted ${white};
    outline: 1px auto -webkit-focus-ring-color;
  }
`;
