import * as React from 'react';
import { useRef, useState } from 'react';
import { NavigationIcons } from '../../../common/helpers/icons';
import { getPrefixRedirectedUrl } from '../../../common/helpers/prefix';
import { SuperLink } from '../../super-link/SuperLink';
import { NavListItemProps } from './NavListItem.props';
import { StyledNavListItem } from './NavListItem.styles';

const interpolate = (input: number[], output: number[]) => {
  if (input.length < 1 || input.length !== output.length) {
    throw new Error('interpolate(): Invalid array lengths');
  }

  return (value: number) => {
    if (value <= input[0]) {
      return output[0];
    }

    if (value >= input[input.length - 1]) {
      return output[output.length - 1];
    }

    let boundIndex = 0;
    for (let i = 1; i < input.length; i++) {
      if (value < input[i]) {
        boundIndex = i - 1;
        break;
      }
    }

    const lowerInput = input[boundIndex];
    const upperInput = input[boundIndex + 1];
    const lowerOutput = output[boundIndex];
    const upperOutput = output[boundIndex + 1];
    const ratio = (value - lowerInput) / (upperInput - lowerInput);
    return ratio * (upperOutput - lowerOutput) + lowerOutput;
  };
};

export const NavListItem = ({ fullWidth, img, tabIndex, target, title, url }: NavListItemProps) => {
  const [mouseOffset, setMouseOffset] = useState<number | undefined>();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const getListItemSize = (): number => {
    const listItem = containerRef.current;
    if (listItem) {
      return fullWidth ? listItem.clientHeight : listItem.clientWidth;
    }

    return 0;
  };

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = (e.target as HTMLDivElement).getBoundingClientRect();
    setMouseOffset(fullWidth ? e.clientY - rect.top : e.clientX - rect.left);
  };

  const onMouseLeave = () => setMouseOffset(undefined);

  const size = getListItemSize();
  const styleObj = {
    transform: `scale(${
      typeof mouseOffset !== 'undefined'
        ? interpolate(
            [0, size / 6, size / 3, (size * 2) / 3, (size * 5) / 6, size],
            [1, 1.025, 1.05, 1.05, 1.025, 1],
          )(mouseOffset)
        : 1
    })`,
  };
  const setStyle = () => ({ style: styleObj });
  const linkUrl = getPrefixRedirectedUrl(url);
  const NavigationIcon = img ? NavigationIcons[img] : () => null;

  return (
    <StyledNavListItem fullWidth={fullWidth} onMouseLeave={onMouseLeave} onMouseMove={onMouseMove} ref={containerRef}>
      <SuperLink getProps={setStyle} style={styleObj} tabIndex={tabIndex} target={target} to={linkUrl}>
        <NavigationIcon />
        <span>{title}</span>
      </SuperLink>
    </StyledNavListItem>
  );
};
