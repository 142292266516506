/* tslint:disable:max-line-length */
import * as React from 'react';
import styled from 'styled-components';
import { hoverMediaQuery, mediaQueryWidth } from './breakpoints';
import { white } from './colors';

export interface SocialIconsProps {
  type: string;
  url: string;
}

export const TwitterIcon = (props: any) => (
  <svg {...props} viewBox='0 0 32 32' focusable='false' aria-label='Twitter'>
    {props.title && <title>{props.title}</title>}
    <path d='M 17.855469 12.695312 L 29.023438 0 L 26.375 0 L 16.679688 11.023438 L 8.933594 0 L 0 0 L 11.710938 16.671875 L 0 29.984375 L 2.648438 29.984375 L 12.886719 18.34375 L 21.066406 29.984375 L 30 29.984375 L 17.851562 12.695312 Z M 14.230469 16.816406 L 13.042969 15.15625 L 3.601562 1.949219 L 7.664062 1.949219 L 15.285156 12.609375 L 16.472656 14.269531 L 26.378906 28.125 L 22.3125 28.125 Z M 14.230469 16.816406 ' />
  </svg>
);

export const FacebookIcon = (props: any) => (
  <svg {...props} viewBox='0 0 32 32' focusable='false' aria-label='Facebook'>
    {props.title && <title>{props.title}</title>}
    <path d='M31.997 15.999c0-8.836-7.163-15.999-15.999-15.999s-15.999 7.163-15.999 15.999c0 7.985 5.851 14.604 13.499 15.804v-11.18h-4.062v-4.625h4.062v-3.525c0-4.010 2.389-6.225 6.043-6.225 1.75 0 3.581 0.312 3.581 0.312v3.937h-2.017c-1.987 0-2.607 1.233-2.607 2.498v3.001h4.437l-0.709 4.625h-3.728v11.18c7.649-1.2 13.499-7.819 13.499-15.804z' />
  </svg>
);

export const PinterestIcon = (props: any) => (
  <svg {...props} viewBox='0 0 32 32' focusable='false' aria-label='Pinterest'>
    {props.title && <title>{props.title}</title>}
    <path d='M16 2.138c-7.656 0-13.863 6.206-13.863 13.863 0 5.875 3.656 10.887 8.813 12.906-0.119-1.094-0.231-2.781 0.050-3.975 0.25-1.081 1.625-6.887 1.625-6.887s-0.412-0.831-0.412-2.056c0-1.925 1.119-3.369 2.506-3.369 1.181 0 1.756 0.887 1.756 1.95 0 1.188-0.756 2.969-1.15 4.613-0.331 1.381 0.688 2.506 2.050 2.506 2.462 0 4.356-2.6 4.356-6.35 0-3.319-2.387-5.638-5.787-5.638-3.944 0-6.256 2.956-6.256 6.019 0 1.194 0.456 2.469 1.031 3.163 0.113 0.137 0.131 0.256 0.094 0.4-0.106 0.438-0.338 1.381-0.387 1.575-0.063 0.256-0.2 0.306-0.463 0.188-1.731-0.806-2.813-3.337-2.813-5.369 0-4.375 3.175-8.387 9.156-8.387 4.806 0 8.544 3.425 8.544 8.006 0 4.775-3.012 8.625-7.194 8.625-1.406 0-2.725-0.731-3.175-1.594 0 0-0.694 2.644-0.863 3.294-0.313 1.206-1.156 2.712-1.725 3.631 1.3 0.4 2.675 0.619 4.106 0.619 7.656 0 13.863-6.206 13.863-13.863 0-7.662-6.206-13.869-13.863-13.869z' />
  </svg>
);

export const InstagramIcon = (props: any) => (
  <svg {...props} viewBox='0 0 32 32' focusable='false' aria-label='Instagram'>
    {props.title && <title>{props.title}</title>}
    <path d='M16 2.881c4.275 0 4.781 0.019 6.462 0.094 1.563 0.069 2.406 0.331 2.969 0.55 0.744 0.288 1.281 0.638 1.837 1.194 0.563 0.563 0.906 1.094 1.2 1.838 0.219 0.563 0.481 1.412 0.55 2.969 0.075 1.688 0.094 2.194 0.094 6.463s-0.019 4.781-0.094 6.463c-0.069 1.563-0.331 2.406-0.55 2.969-0.288 0.744-0.637 1.281-1.194 1.837-0.563 0.563-1.094 0.906-1.837 1.2-0.563 0.219-1.413 0.481-2.969 0.55-1.688 0.075-2.194 0.094-6.463 0.094s-4.781-0.019-6.463-0.094c-1.563-0.069-2.406-0.331-2.969-0.55-0.744-0.288-1.281-0.637-1.838-1.194-0.563-0.563-0.906-1.094-1.2-1.837-0.219-0.563-0.481-1.413-0.55-2.969-0.075-1.688-0.094-2.194-0.094-6.463s0.019-4.781 0.094-6.463c0.069-1.563 0.331-2.406 0.55-2.969 0.288-0.744 0.638-1.281 1.194-1.838 0.563-0.563 1.094-0.906 1.838-1.2 0.563-0.219 1.412-0.481 2.969-0.55 1.681-0.075 2.188-0.094 6.463-0.094zM16 0c-4.344 0-4.887 0.019-6.594 0.094-1.7 0.075-2.869 0.35-3.881 0.744-1.056 0.412-1.95 0.956-2.837 1.85-0.894 0.888-1.438 1.781-1.85 2.831-0.394 1.019-0.669 2.181-0.744 3.881-0.075 1.713-0.094 2.256-0.094 6.6s0.019 4.887 0.094 6.594c0.075 1.7 0.35 2.869 0.744 3.881 0.413 1.056 0.956 1.95 1.85 2.837 0.887 0.887 1.781 1.438 2.831 1.844 1.019 0.394 2.181 0.669 3.881 0.744 1.706 0.075 2.25 0.094 6.594 0.094s4.888-0.019 6.594-0.094c1.7-0.075 2.869-0.35 3.881-0.744 1.050-0.406 1.944-0.956 2.831-1.844s1.438-1.781 1.844-2.831c0.394-1.019 0.669-2.181 0.744-3.881 0.075-1.706 0.094-2.25 0.094-6.594s-0.019-4.887-0.094-6.594c-0.075-1.7-0.35-2.869-0.744-3.881-0.394-1.063-0.938-1.956-1.831-2.844-0.887-0.887-1.781-1.438-2.831-1.844-1.019-0.394-2.181-0.669-3.881-0.744-1.712-0.081-2.256-0.1-6.6-0.1v0z' />
    <path d='M16 7.781c-4.537 0-8.219 3.681-8.219 8.219s3.681 8.219 8.219 8.219 8.219-3.681 8.219-8.219c0-4.537-3.681-8.219-8.219-8.219zM16 21.331c-2.944 0-5.331-2.387-5.331-5.331s2.387-5.331 5.331-5.331c2.944 0 5.331 2.387 5.331 5.331s-2.387 5.331-5.331 5.331z' />
    <path d='M26.462 7.456c0 1.060-0.859 1.919-1.919 1.919s-1.919-0.859-1.919-1.919c0-1.060 0.859-1.919 1.919-1.919s1.919 0.859 1.919 1.919z' />
  </svg>
);

export const LinkedInIcon = (props: any) => (
  <svg {...props} viewBox='0 0 32 32' focusable='false' aria-label='LinkedIn'>
    {props.title && <title>{props.title}</title>}
    <path d='M12 12h5.535v2.837h0.079c0.77-1.381 2.655-2.837 5.464-2.837 5.842 0 6.922 3.637 6.922 8.367v9.633h-5.769v-8.54c0-2.037-0.042-4.657-3.001-4.657-3.005 0-3.463 2.218-3.463 4.509v8.688h-5.767v-18z' />
    <path d='M2 12h6v18h-6v-18z' />
    <path d='M8 7c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-1.657 1.343-3 3-3s3 1.343 3 3z' />
  </svg>
);

export const YouTubeIcon = (props: any) => (
  <svg {...props} viewBox='0 0 32 32' focusable='false' aria-label='Youtube'>
    {props.title && <title>{props.title}</title>}
    <path d='M31.681 9.6c0 0-0.313-2.206-1.275-3.175-1.219-1.275-2.581-1.281-3.206-1.356-4.475-0.325-11.194-0.325-11.194-0.325h-0.012c0 0-6.719 0-11.194 0.325-0.625 0.075-1.987 0.081-3.206 1.356-0.963 0.969-1.269 3.175-1.269 3.175s-0.319 2.588-0.319 5.181v2.425c0 2.587 0.319 5.181 0.319 5.181s0.313 2.206 1.269 3.175c1.219 1.275 2.819 1.231 3.531 1.369 2.563 0.244 10.881 0.319 10.881 0.319s6.725-0.012 11.2-0.331c0.625-0.075 1.988-0.081 3.206-1.356 0.962-0.969 1.275-3.175 1.275-3.175s0.319-2.587 0.319-5.181v-2.425c-0.006-2.588-0.325-5.181-0.325-5.181zM12.694 20.15v-8.994l8.644 4.513-8.644 4.481z' />
  </svg>
);

export const NewsletterIcon = (props: any) => (
  <svg {...props} viewBox='0 0 28 28' focusable='false' aria-label='Newsletter'>
    {props.title && <title>{props.title}</title>}
    <path d='M28 11.094v12.406c0 1.375-1.125 2.5-2.5 2.5h-23c-1.375 0-2.5-1.125-2.5-2.5v-12.406c0.469 0.516 1 0.969 1.578 1.359 2.594 1.766 5.219 3.531 7.766 5.391 1.313 0.969 2.938 2.156 4.641 2.156h0.031c1.703 0 3.328-1.188 4.641-2.156 2.547-1.844 5.172-3.625 7.781-5.391 0.562-0.391 1.094-0.844 1.563-1.359zM28 6.5c0 1.75-1.297 3.328-2.672 4.281-2.438 1.687-4.891 3.375-7.313 5.078-1.016 0.703-2.734 2.141-4 2.141h-0.031c-1.266 0-2.984-1.437-4-2.141-2.422-1.703-4.875-3.391-7.297-5.078-1.109-0.75-2.688-2.516-2.688-3.938 0-1.531 0.828-2.844 2.5-2.844h23c1.359 0 2.5 1.125 2.5 2.5z' />
  </svg>
);

export const SnapchatIcon = (props: any) => (
  <svg {...props} viewBox='0 0 26 28' focusable='false' aria-label='Snapchat'>
    {props.title && <title>{props.title}</title>}
    <path d='M13.25 2c2.812-0.031 5.141 1.547 6.312 4.078 0.359 0.766 0.422 1.953 0.422 2.797 0 1-0.078 1.984-0.141 2.984 0.125 0.063 0.297 0.109 0.438 0.109 0.562 0 1.031-0.422 1.594-0.422 0.531 0 1.297 0.375 1.297 1 0 1.5-3.141 1.219-3.141 2.531 0 0.234 0.094 0.453 0.187 0.672 0.75 1.641 2.172 3.219 3.859 3.922 0.406 0.172 0.812 0.266 1.25 0.359 0.281 0.063 0.438 0.266 0.438 0.547 0 1.062-2.703 1.5-3.422 1.609-0.313 0.484-0.078 1.625-0.906 1.625-0.641 0-1.281-0.203-1.969-0.203-0.328 0-0.656 0.016-0.969 0.078-1.859 0.313-2.484 2.312-5.531 2.312-2.938 0-3.641-2-5.453-2.312-0.328-0.063-0.656-0.078-0.984-0.078-0.703 0-1.375 0.234-1.937 0.234-0.875 0-0.609-1.156-0.938-1.656-0.719-0.109-3.422-0.547-3.422-1.609 0-0.281 0.156-0.484 0.438-0.547 0.438-0.094 0.844-0.187 1.25-0.359 1.672-0.688 3.125-2.281 3.859-3.922 0.094-0.219 0.187-0.438 0.187-0.672 0-1.313-3.156-1.062-3.156-2.516 0-0.609 0.719-1 1.266-1 0.484 0 0.969 0.406 1.578 0.406 0.172 0 0.344-0.031 0.5-0.109-0.063-0.984-0.141-1.969-0.141-2.969 0-0.844 0.063-2.047 0.422-2.812 1.375-2.969 3.703-4.047 6.813-4.078z' />
  </svg>
);

export const TumblrIcon = (props: any) => (
  <svg {...props} viewBox='0 0 32 32' focusable='false' aria-label='Tumblr'>
    {props.title && <title>{props.title}</title>}
    <path d='M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h26c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3zM22.869 25.769c-0.944 0.444-1.8 0.756-2.563 0.938-0.762 0.175-1.594 0.269-2.481 0.269-1.012 0-1.606-0.125-2.381-0.381s-1.438-0.619-1.988-1.087c-0.55-0.475-0.925-0.975-1.137-1.506s-0.319-1.3-0.319-2.313v-7.744h-3v-3.125c0.869-0.281 1.875-0.688 2.488-1.213 0.619-0.525 1.119-1.156 1.488-1.894 0.375-0.737 0.631-1.675 0.775-2.813h3.138v5.1h5.113v3.944h-5.106v5.662c0 1.281-0.019 2.019 0.119 2.381s0.475 0.738 0.844 0.95c0.488 0.294 1.050 0.438 1.675 0.438 1.119 0 2.231-0.363 3.337-1.087v3.481z' />
  </svg>
);

export const GooglePlusIcon = (props: any) => (
  <svg {...props} viewBox='0 0 32 32' focusable='false' aria-label='Google+'>
    {props.title && <title>{props.title}</title>}
    <path d='M10.181 14.294v3.494h5.775c-0.231 1.5-1.744 4.394-5.775 4.394-3.475 0-6.313-2.881-6.313-6.431s2.838-6.431 6.313-6.431c1.981 0 3.3 0.844 4.056 1.569l2.762-2.662c-1.775-1.656-4.075-2.662-6.819-2.662-5.631 0.006-10.181 4.556-10.181 10.188s4.55 10.181 10.181 10.181c5.875 0 9.775-4.131 9.775-9.95 0-0.669-0.075-1.181-0.163-1.688h-9.613z' />
    <path d='M32 14h-3v-3h-3v3h-3v3h3v3h3v-3h3z' />
  </svg>
);

export const TikTokIcon = (props: any) => (
  <svg {...props} viewBox='0 0 32 32' focusable='false' aria-label='TikTok'>
    {props.title && <title>{props.title}</title>}
    <path d='M24.8199768,5.93076326 C21.9859162,5.93076326 19.6802292,3.62507632 19.6802292,0.791015625 C19.6802292,0.354103102 19.3261261,0 18.8892135,0 L14.6527481,0 C14.2160416,0 13.8617325,0.354103102 13.8617325,0.791015625 L13.8617325,18.1599884 C13.8617325,19.8260651 12.5060882,21.1815033 10.8400116,21.1815033 C9.17393491,21.1815033 7.81849673,19.8260651 7.81849673,18.1599884 C7.81849673,16.4937057 9.17393491,15.1382675 10.8400116,15.1382675 C11.2769241,15.1382675 11.6310272,14.7841644 11.6310272,14.3472519 L11.6310272,10.1107865 C11.6310272,9.67407991 11.2769241,9.31977083 10.8400116,9.31977083 C5.96558381,9.31977083 2,13.2855606 2,18.1599884 C2,23.0344162 5.96558381,27 10.8400116,27 C15.7144394,27 19.6802292,23.0344162 19.6802292,18.1599884 L19.6802292,10.4716873 C21.2544327,11.3111115 23.0064087,11.7492599 24.8199768,11.7492599 C25.2568893,11.7492599 25.6109924,11.3951569 25.6109924,10.9582443 L25.6109924,6.72177888 C25.6109924,6.28507234 25.2568893,5.93076326 24.8199768,5.93076326 Z' />
  </svg>
);

export const getSocialIcon = (icon: SocialIconsProps) => {
  switch (icon.type) {
    case 'facebook':
      return <FacebookIcon className='facebook-icon social-icon' title={icon.type} />;
    case 'twitter':
      return <TwitterIcon className='twitter-icon social-icon' title={icon.type} viewBox='0 -70 199 279' />;
    case 'instagram':
      return <InstagramIcon className='instagram-icon social-icon' title={icon.type} viewBox='0 0 512 512' />;
    case 'pinterest':
      return <PinterestIcon className='pinterest-icon social-icon' title={icon.type} viewBox='0 0 512 512' />;
    case 'youtube':
      return <YouTubeIcon className='youtube-icon social-icon' title={icon.type} viewBox='0 0 512 512' />;
    case 'snapchat':
      return <SnapchatIcon className='snapchat-icon social-icon' title={icon.type} viewBox='0 0 512 512' />;
    case 'tumblr':
      return <TumblrIcon className='tumblr-icon social-icon' title={icon.type} viewBox='0 0 512 512' />;
    case 'google+':
      return <GooglePlusIcon className='google-plus-icon social-icon' title={icon.type} viewBox='0 0 512 512' />;
    case 'newsletter':
      return <NewsletterIcon className='newsletter-icon social-icon' title={icon.type} viewBox='0 0 512 512' />;
    case 'tiktok':
      return <TikTokIcon className='tiktok-icon social-icon' title={icon.type} viewBox='0 0 512 512' />;
  }
};

export const StyledSocialIconLink = styled.a`
  svg {
    height: 18px;
    width: auto;
    fill: ${white};

    // Hack IE 11 into submission
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 36px;
    }

    ${mediaQueryWidth({ min: 'tablet' })`
      height: 27px;
    `}

    ${hoverMediaQuery} {
      :hover {
        transform: scale(1.25);
        transition: background-color 0.5s, transform 0.5s ease-out;
      }

      &.twitter-icon:hover {
        fill: #00a2f5;
      }

      &.facebook-icon:hover {
        fill: #1778f2;
      }

      &.instagram-icon:hover {
        fill: #c40091;
      }

      &.youtube-icon:hover {
        fill: #ff2500;
      }

      &.tumblr-icon:hover {
        fill: #526a86;
      }

      &.google-plus-icon:hover {
        fill: #dc4e41;
      }

      &.pinterest-icon:hover {
        fill: #e90023;
      }

      &.snapchat-icon:hover {
        fill: #fcfb1d;
      }

      &.newsletter-icon:hover {
        fill: #1bb0fc;
      }

      &.tiktok-icon:hover {
        fill: #fc2c4b;
      }
    }

    ${mediaQueryWidth({ max: 'tablet' })`
      &.twitter-icon:active {
        fill: #00a2f5;
      }

      &.facebook-icon:active {
        fill: #1778f2;
      }

      &.instagram-icon:active {
        fill: #c40091;
      }

      &.youtube-icon:active {
        fill: #ff2500;
      }

      &.tumblr-icon:active {
        fill: #526a86;
      }

      &.google-plus-icon:active {
        fill: #dc4e41;
      }

      &.pinterest-icon:active {
        fill: #e90023;
      }

      &.snapchat-icon:active {
        fill: #fcfb1d;
      }

      &.newsletter-icon:active {
        fill: #1bb0fc;
      }

      &.tiktok-icon:active {
        fill: #fc2c4b;
      }
    `}
  }
`;

export const getSocialLinks = (socialIcons: SocialIconsProps[]) =>
  (socialIcons || [])
    .map((icon: SocialIconsProps) => {
      const socialIcon = getSocialIcon(icon);
      return (
        socialIcon && (
          <li key={icon.type}>
            <StyledSocialIconLink className={icon.type} href={icon.url} target='_blank' title={icon.type}>
              {socialIcon}
            </StyledSocialIconLink>
          </li>
        )
      );
    })
    .filter(Boolean);
