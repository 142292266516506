import { get } from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import { useSiteData } from 'react-static';
import { white } from '../../common/colors';
import { windowLocationHash } from '../../common/helpers/window';
import { useDebug } from '../../common/hooks/useDebug';
import { LocaleProvider } from '../../common/hooks/useLocale';
import { DEFAULT_LOCALE } from '../../common/translation';
import { BrowserNotice } from '../../components/browser-notice/BrowserNotice';
import { ConditionalPopup } from '../../components/conditional-popup/ConditionalPopup';
import { CountryChecker } from '../../components/country-checker/CountryChecker';
import { FloatingNotice } from '../../components/floating-notice/FloatingNotice';
import { Footer } from '../../components/footer/Footer';
import { LoadGTM } from '../../components/gtm/LoadGTM';
import { HeadHreflang } from '../../components/head-hreflang/HeadHrefLang';
import { HeadMeta } from '../../components/head-meta/HeadMeta';
import { Navigation } from '../../components/navigation/Navigation';
import { SkipNavigation } from '../../components/skip-navigation/SkipNavigation';
import { GTM_ID } from '../../config/browser';
import { DefaultLayoutProps } from './DefaultLayout.props';
import { StyledDefaultLayout } from './DefaultLayout.styles';

const onConsentChange = (e: any) => {
  if (e.target && e.target.Optanon && e.target.Optanon.IsAlertBoxClosed()) {
    document.querySelector('body')!.style.transform = 'none';
  }
};

export const DefaultLayout = ({
  bgColor = white,
  children,
  className,
  headLanguage,
  hideBottomPadding,
  locale = DEFAULT_LOCALE,
  seo,
  translatedPaths,
}: DefaultLayoutProps) => {
  const { hrefLangData } = useSiteData();

  useEffect(() => {
    const hash = windowLocationHash();
    if (hash) {
      const elem = document.getElementById(hash);
      if (elem) {
        setTimeout(() => {
          elem.scrollIntoView();
        }, 50);
      }
    } else {
      window.scrollTo(0, 0);
    }

    window.addEventListener('consent.onetrust', onConsentChange);

    return () => {
      window.removeEventListener('consent.onetrust', onConsentChange);
    };
  }, []);

  useDebug('app:layout:DefaultLayout', {
    props: { bgColor, children, className, hideBottomPadding, locale, seo, translatedPaths },
  });

  const description = get(seo, 'description', '');
  const styledDefaultLayoutProps = { bgColor, className, hideBottomPadding };

  return (
    <LocaleProvider locale={locale}>
      <HeadMeta locale={headLanguage || locale} description={description} />
      <HeadHreflang data={hrefLangData} />
      <StyledDefaultLayout {...styledDefaultLayoutProps} id='default-layout'>
        <CountryChecker />
        <SkipNavigation label='Skip to content' targetElement='content' />
        <Navigation translatedPaths={translatedPaths} />
        <BrowserNotice />
        <div className='content' id='content'>
          <LoadGTM id={GTM_ID} />
          {children}
        </div>
        <Footer />
        <FloatingNotice />
        <ConditionalPopup />
      </StyledDefaultLayout>
    </LocaleProvider>
  );
};
