import { getRedirectedUrl } from 'origin_response_handler';
import { MARKET } from '../../config/browser';
import { isMultiCountry } from '../countries';
import { DEFAULT_LOCALE, LOCALE_CONFIG } from '../translation';
import { getCountryPrefixFromPath, stripCountryPrefixFromPath } from './countrySelection';
import { isAbsoluteUrl } from './strings';

export const getLanguagePrefixFromPath = (): string | undefined => {
  if (typeof window !== 'undefined') {
    const matched = window.location.pathname.match(/^(\/[a-z]{2})(?:$|\/)/i);
    if (matched && LOCALE_CONFIG.find((config) => config.prefix === matched[1].toLowerCase())) {
      return `${matched[1].toLowerCase()}`;
    }
  }
};

export const updatePathWithPrefix = (path: string): string => {
  if (!path.startsWith('/')) {
    return path;
  }

  const prefix = isMultiCountry() ? getCountryPrefixFromPath() : getLanguagePrefixFromPath();
  return prefix ? `${prefix}${path}` : path;
};

export const getLocaleFromPath = (): string => {
  if (!isMultiCountry()) {
    const prefix = getLanguagePrefixFromPath();
    if (prefix) {
      const filtered = LOCALE_CONFIG.find((config) => config.prefix === prefix);
      if (filtered) {
        return filtered.name;
      }
    }
  }

  return DEFAULT_LOCALE;
};

const stripLanguagePrefixFromPath = (path: string): string =>
  path.replace(/^(\/[a-z]{2})($|\/)/i, (str, p1, p2) => {
    if (LOCALE_CONFIG.find((config) => config.prefix === p1)) {
      return p2;
    }

    return str;
  });

export const stripPrefixFromPath = (path: string): string => {
  if (!path.startsWith('/')) {
    return path;
  }

  return isMultiCountry() ? stripCountryPrefixFromPath(path) : stripLanguagePrefixFromPath(path);
};

export const getPrefixRedirectedUrl = (path: string): string => getRedirectedUrl(updatePathWithPrefix(path), MARKET);

export const updatePathWithLocale = (path: string, locale: string | undefined) => {
  if (isMultiCountry() && locale && path && !isAbsoluteUrl(path)) {
    const separator = path.startsWith('/') ? '' : '/';
    return `/${locale.toLowerCase()}${separator}${path}`;
  }

  return path;
};
