import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { totalNavigationPxHeight } from '../../components/navigation/Navigation.styles';

export interface StyledDefaultLayoutProps {
  bgColor: string;
  hideBottomPadding?: boolean;
}

export const StyledDefaultLayout = styled.div<StyledDefaultLayoutProps>`
  & > .content {
    padding: ${rem(totalNavigationPxHeight)} 0 2rem 0;
    background-color: ${({ bgColor }: { bgColor: string }) => bgColor};

    ${({ hideBottomPadding }) =>
      hideBottomPadding &&
      css`
        padding-bottom: 0;
      `}
  }

  @media print {
    margin: 0;
  }
`;
