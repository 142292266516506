import * as React from 'react';
import { getMappedLink } from '../../../common/footer';
import { useLocale } from '../../../common/hooks/useLocale';
import linkTranslations from '../../footer/translations/footer-links';

export const FloatingNoticeBodyUS = () => {
  const locale = useLocale();
  const pkey = 'policyLinks';
  const links = linkTranslations[locale][pkey];
  const arrTerms = getMappedLink(links, 'terms');
  const arrPrivacy = getMappedLink(links, 'privacy');
  if (arrTerms && arrPrivacy) {
    return (
      <p>
        {'By using this site, you agree to the '}
        {arrPrivacy}
        {' and '}
        {arrTerms}.
      </p>
    );
  }
  return null;
};
