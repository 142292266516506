import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { hoverMediaQuery, mediaQueryWidth, widthBreakpoints } from '../../common/breakpoints';
import { black, lightBlue20, white } from '../../common/colors';
import { routerLinkButtonClass, SuperButton } from './super-button/SuperButton';
import { SuperButtonProps } from './super-button/SuperButton.props';

const smallButtonStyles = `
  font-size: ${rem(12)};
  line-height: ${rem(16)};
  padding: ${rem(8)} ${rem(16)};

  .MuiButton-label svg {
    width: ${rem(16)};
    height: ${rem(16)};
    margin-right: ${rem(8)};
  }
`;

const mediumButtonStyles = `
  font-size: ${rem(14)};
  line-height: ${rem(18)};
  padding: ${rem(12)} ${rem(26)};

  .MuiButton-label svg {
    width: ${rem(18)};
    height: ${rem(18)};
    margin-right: ${rem(12)};
  }
`;

const largeButtonStyles = `
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  padding: ${rem(16)} ${rem(32)};

  .MuiButton-label svg {
    width: ${rem(20)};
    height: ${rem(20)};
    margin-right: ${rem(16)};
  }
`;

export const StyledButton = styled(SuperButton)<SuperButtonProps>`
  &.MuiButton-root,
  & > .MuiButton-root {
    ${({ invertColors, loading }) => {
      const primaryColor = invertColors ? white : black;
      const secondaryColor = invertColors ? black : white;

      return `
        &, &.Mui-disabled {
          color: ${primaryColor};
        }

        &.Mui-disabled {
          opacity: 0.5;
        }

        ${hoverMediaQuery} {
          &:hover {
            color: ${secondaryColor};
            background-color: ${primaryColor};
          }
        }

        &:active {
          color: ${secondaryColor};
          background-color: ${primaryColor};
        }

        ${
          loading
            ? `
              &.MuiButton-root {
                color: ${secondaryColor};
                background-color: ${primaryColor};
                border-color: ${lightBlue20};
              }

              .MuiButton-label {
                visibility: hidden;
              }
            `
            : ''
        }
      `;
    }}

    ${({ size }) => {
      if (size === 'sm') {
        return smallButtonStyles;
      }

      if (size === 'md') {
        return mediumButtonStyles;
      }

      if (size === 'lg') {
        return largeButtonStyles;
      }

      return `
        ${smallButtonStyles}
        @media (min-width: ${widthBreakpoints.mobile}px) {
          ${largeButtonStyles}
        }
      `;
    }}

    ${({ fullWidthBreakpoint, leftAlign }) =>
      fullWidthBreakpoint &&
      css`
        ${mediaQueryWidth(fullWidthBreakpoint)`
          width: 100%;
          justify-content: ${leftAlign ? 'flex-start' : 'center'};
        `}
      `}

    position: relative;
    border-width: 0;
    border-radius: 0;
    outline: none;
    font-weight: 700;
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    letter-spacing: 0;
    text-transform: ${({ disableCaps }) => (disableCaps ? 'none' : 'capitalize')};
    text-shadow: none;
    background-color: transparent;
    white-space: nowrap;
    cursor: pointer;

    .MuiButton-label svg {
      vertical-align: text-bottom;
      line-height: 0;
    }
  }

  && {
    border-width: 2px;
    border-style: solid;
    border-radius: 0;
    outline: none;

    ${({ invertColors, loading }) => {
      const primaryColor = invertColors ? white : black;

      return `
        border-color: ${loading ? lightBlue20 : primaryColor};

        &:active,
        &:focus {
          border-color: ${lightBlue20};
        }

        &.Mui-disabled {
          border-color: ${primaryColor};
        }
      `;
    }}
  }

  &.${routerLinkButtonClass} {
    display: inline-block;
  }

  ${({ fullWidthBreakpoint }) =>
    fullWidthBreakpoint &&
    css`
      ${mediaQueryWidth(fullWidthBreakpoint)`
        &.${routerLinkButtonClass} {
          display: block;
        }
      `}
    `}
`;
