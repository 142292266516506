import { rem } from 'polished';
import styled from 'styled-components';
import { grey1, white } from '../../../common/colors';
import { langSwitcherPxHeight } from '../Navigation.styles';

export const StyledLanguageSwitcher = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${rem(langSwitcherPxHeight)};
  color: ${white};
  background-color: ${grey1};

  & > svg {
    width: ${rem(14)};
    padding: 0 ${rem(10)} 0 0;
  }
`;

export const StyledLSOption = styled.span<{ current?: boolean }>`
  font-size: ${rem(12)};
  margin-right: ${rem(20)};
  ${({ current }) => current && `border-bottom: ${rem(1)} solid ${white};`}

  a {
    color: ${white};
  }
`;
