import * as Debug from 'debug';
import * as moment from 'moment';
import 'moment-timezone';
import { DEFAULT_LOCALE, mapToTranslationLocale, Translation, useTranslations } from '../../translation';
import translations from './translations';
import formatTranslations from './translations/date-format';

const debug = Debug('app:helper:date');

export const dateStringToYear = (date: string): string => {
  const yearMatch = (date || '').match(/[0-9]{4}/);
  return yearMatch ? yearMatch[0] : '';
};

/*
 * Attempt to provide date format to moment.js to avoid deprecation warnings.
 */
const dateStringToFormat = (date: string) => (/[A-Z][a-z]+ [0-9]{1,2}, [0-9]{4}/.test(date) ? 'MMMM D, YYYY' : '');

export const momentDate = (
  date?: string | number | undefined,
  locale: string = DEFAULT_LOCALE,
  timeZone: string = '',
  formatOfDateInput?: string | undefined,
) => {
  const translationLocale = mapToTranslationLocale(locale);
  const dateFormat = Translation(formatTranslations[translationLocale]);
  const marketTimeZone = dateFormat('timeZone', 'America/Los_Angeles');
  const tz = timeZone || marketTimeZone;

  if (date) {
    if (typeof date === 'number') {
      return moment(date);
    }

    const formatOfInput = formatOfDateInput || dateStringToFormat(date);
    return moment.tz(date, formatOfInput, tz);
  }

  return moment.tz(tz);
};

const numberToMonth = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};
export const formattedDate = (
  date: string | number,
  locale: string = DEFAULT_LOCALE,
  format: 'default' | 'news' = 'default',
  timeZone: string = '',
): string => {
  try {
    const translationLocale = mapToTranslationLocale(locale);
    const t = Translation(translations[translationLocale]);
    const dateFormat = Translation(formatTranslations[translationLocale]);

    const marketTimeZone = dateFormat('timeZone', 'America/Los_Angeles');
    const momentObj = momentDate(date, locale, timeZone);
    if (!momentObj.isValid()) {
      return '';
    }

    const normalizedMoment = momentObj.tz(marketTimeZone);
    const year = String(normalizedMoment.year());
    const month = normalizedMoment.month() + 1;
    const day = String(normalizedMoment.date());
    const time = String(normalizedMoment.format('h:mm A z'));
    const params = {
      day,
      time,
      year,
      month: t(`month${month}Text`, numberToMonth[month]),
    };
    return format === 'news'
      ? dateFormat('newsDateFormat', '%(month)s %(day)s, %(year)s at %(time)s', params)
      : dateFormat('dateFormat', '%(month)s %(day)s, %(year)s', params);
  } catch (err) {
    debug(err);
    return '';
  }
};

export const useDateTranslations = () => useTranslations(translations);

export const useDateFormatTranslations = () => useTranslations(formatTranslations);
