import * as React from 'react';
import { StyledRipple } from './Ripple.styles';

export const Ripple = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <StyledRipple {...props}>
    <div />
    <div />
    <div />
  </StyledRipple>
);
