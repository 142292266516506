import * as React from 'react';
import { useEffect } from 'react';
import CloseIcon from '../../assets/images/icons/svg/close.svg';
import { MARKET } from '../../config/browser';
import { FloatingNoticeBody } from './floating-notice-body/FloatingNoticeBody';
import { StyledLegalNotice, StyledLegalNoticeCloseButton, StyledLegalNoticeContainer } from './FloatingNotice.styles';

const floatingNoticeId = 'WB-floating-notice';

const setCookie = (cname: string, cvalue: any, exdays?: number) => {
  const d = new Date();
  let expires = '';
  if (exdays) {
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    expires = `expires=${d.toUTCString()}`;
  }
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

const getCookie = (cname: string) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const allCookies = decodedCookie.split(';');
  for (let cookie of allCookies) {
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
};

export const FloatingNotice = () => {
  if (!['US', 'CA'].includes(MARKET)) {
    return null;
  }

  const closeFloatingNotice = () => {
    setCookie('CloseWBFloatingNotice', true);
    const el = document.getElementById(floatingNoticeId);
    if (el) {
      el.style.display = 'none';
    }
  };

  useEffect(() => {
    if (!getCookie('CloseWBFloatingNotice')) {
      const el = document.getElementById(floatingNoticeId);
      if (el) {
        el.style.display = 'flex';
      }
    }
  }, []);

  return (
    <StyledLegalNoticeContainer id={floatingNoticeId}>
      <StyledLegalNotice>
        <FloatingNoticeBody />
      </StyledLegalNotice>
      <StyledLegalNoticeCloseButton>
        {/* tslint:disable-next-line:react-a11y-anchors */}
        <a role='button' className='close' aria-label='Close' onClick={closeFloatingNotice}>
          <CloseIcon />
        </a>
      </StyledLegalNoticeCloseButton>
    </StyledLegalNoticeContainer>
  );
};
