import { motion } from 'framer-motion';
import styled from 'styled-components';
import { darkGrey1, darkGrey3, white } from '../../../common/colors';
import { NavListDirection } from './NavList.props';

export const StyledNavList = styled(motion.ul)<{ direction: NavListDirection }>`
  color: ${white};
  background-color: ${darkGrey1};

  ${({ direction }) =>
    direction === 'vertical'
      ? `
        height: 100%;
        padding: 3px 0;
        overflow-x: hidden;
        overflow-y: auto;

        li {
          border-bottom: 1px solid ${darkGrey3};
        }
      `
      : `
        display:flex;
      `}
`;
