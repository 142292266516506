import * as moment from 'moment';
import * as React from 'react';
import { FooterLinkProps } from '../components/footer/Footer.props';
import { SuperLink } from '../components/super-link/SuperLink';
import { getPrefixRedirectedUrl } from './helpers/prefix';

const convertDateToTimestamp = (dateVal: string) => moment(dateVal, 'DD/MM/YYYY HH:mm:ss').format('X');

const getUpdatedText = (link: any): string => {
  if (link.updated_start_date && link.updated_end_date) {
    const tsNow = moment().format('X');
    const tsStart = convertDateToTimestamp(`${link.updated_start_date} 00:00:00`);
    const tsEnd = convertDateToTimestamp(`${link.updated_end_date} 23:59:59`);
    if (tsStart <= tsNow && tsEnd >= tsNow && tsStart <= tsEnd) {
      return '(Updated)';
    }
  }

  return '';
};

export const linkMap = (link: FooterLinkProps) => {
  const url = getPrefixRedirectedUrl(link.url);
  const updatedText = getUpdatedText(link);

  return (
    <SuperLink key={url} target={link.target} to={url}>
      {link.title}
      {updatedText && <span>{updatedText}</span>}
      {link.subtitle && <span>{link.subtitle}</span>}
    </SuperLink>
  );
};

export const getMappedLink = (links: FooterLinkProps[], key: string) => {
  const matchingLink = links.find((link) => link.key === key);
  return matchingLink ? linkMap(matchingLink) : null;
};
