import { rem } from 'polished';
import styled from 'styled-components';
import { white } from '../../../common/colors';
import { navigationPxHeight } from '../Navigation.styles';

export const StyledNavListItem = styled.div<{ fullWidth?: boolean }>`
  height: ${rem(navigationPxHeight)};

  & > a {
    display: flex;
    height: 100%;
    padding-right: ${rem(50)};
    ${({ fullWidth }) => fullWidth && `padding-left: ${rem(24)};`}
    align-items: center;
    color: inherit;
    transform: scale(1);
    transform-origin: 0 0;
    text-align: center;
    white-space: nowrap;

    &:hover,
    &:focus {
      color: ${white};
    }

    svg {
      width: ${({ fullWidth }) => rem(fullWidth ? 18 : 20)};
      margin-right: ${({ fullWidth }) => rem(fullWidth ? 38 : 10)};
    }

    * {
      pointer-events: none;
    }
  }

  &:hover > a {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.75);
  }
`;
