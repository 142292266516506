import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDebug } from '../../../common/hooks/useDebug';
import { useTranslations } from '../../../common/translation';
import { CountryIndicator } from '../country-indicator/CountryIndicator';
import { Hamburger } from '../hamburger/Hamburger';
import { Logo } from '../logo/Logo';
import { NavList } from '../nav-list/NavList';
import { NavSearch } from '../nav-search/NavSearch';
import { InnerNavProps } from '../Navigation.props';
import { StyledInnerNav } from '../Navigation.styles';
import translations from '../translations/navigation';
import { StyledNavListContainer } from './MobileNav.styles';

const variants = {
  open: {
    opacity: 1,
    height: 'calc(100vh - 100%)',
    transition: {
      duration: 0.3,
      ease: 'easeIn',
    },
  },
  closed: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.3,
      ease: 'easeOut',
    },
  },
};

export const MobileNav = ({ location, navListItems }: InnerNavProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [hideElements, setHideElements] = useState<boolean>(false);
  const { t } = useTranslations(translations);

  const hasNavList = navListItems.length > 0;

  const toggle = () => {
    const element = document.querySelector('[data-component="nav-list"]');
    if (element) {
      open ? enableBodyScroll(element) : disableBodyScroll(element);
    }
    setOpen(!open);
  };

  const onSearchOpen = (searchOpen: boolean) => setHideElements(searchOpen);

  useEffect(() => {
    if (open) {
      toggle();
    }
  }, [location]);

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  useDebug('app:cmp:MobileNav', { props: { location, navListItems }, state: { open } });

  return (
    <StyledInnerNav aria-label={t('ariaMainNavigation', 'Main navigation')} role='navigation'>
      <Logo />
      {hasNavList && (
        <>
          <Hamburger onClick={toggle} open={open} />
          <StyledNavListContainer animate={open ? 'open' : 'closed'} initial='closed' variants={variants}>
            <NavList direction='vertical' navListItems={navListItems} open={open} />
          </StyledNavListContainer>
        </>
      )}
      <CountryIndicator hide={hideElements} />
      <NavSearch onSearchOpen={onSearchOpen} truncateWidth={hasNavList} />
    </StyledInnerNav>
  );
};
