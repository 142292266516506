import { Dialog } from '@material-ui/core';
import { rem } from 'polished';
import styled from 'styled-components';
import { hoverMediaQuery, mediaQueryWidth, noHoverMediaQuery } from './breakpoints';
import { black, grey10, grey2, grey40, grey90, lightBlue, lightGrey2, lightGrey7 } from './colors';

export const mobileImageWidth = 60;
export const gap = 10;
export const spacingXs = 5;
export const spacingSm = 10;
export const spacingMd = 20;
export const spacingLg = 40;

export const mobileLinkWidth = 100;
export const mobileLinkHeight = 60;
export const tabletLinkWidth = 146;
export const desktopLinkWidth = 200;
export const desktopLinkHeight = 80;

const mobileDialogMargin = 15;
const tabletDialogMargin = 40;
const desktopDialogMargin = 80;

export const StyledPopup = styled.div<{ hidePadding?: boolean; show?: boolean }>`
  position: relative;
  overflow-y: scroll;
  line-height: 0;
  background-color: white;
  transition: opacity 0.4s cubic-bezier(0, 0, 0.2, 1);
  opacity: ${({ show }) => (show ? '1' : '0')};
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);

  ${mediaQueryWidth({ min: 'mobile' })`
    display: flex;
  `}

  ${mediaQueryWidth({ min: 'mobile', max: 'tablet' })`
    padding: ${({ hidePadding = false }: { hidePadding?: boolean }) =>
      hidePadding ? 0 : `${spacingMd}px 60px ${spacingMd}px ${spacingMd}px`};
  `}

  ${mediaQueryWidth({ min: 'tablet' })`
    padding: ${({ hidePadding = false }: { hidePadding?: boolean }) =>
      hidePadding ? 0 : `${spacingLg}px 80px ${spacingLg}px ${spacingLg}px`};
  `}
`;

export const StyledCloseButton = styled.button`
  position: absolute;
  z-index: 1;
  top: ${spacingSm}px;
  right: ${spacingSm}px;
  margin: 0;
  padding: 0;
  line-height: 0;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    width: 14px;
    height: 24px;
  }

  ${mediaQueryWidth({ min: 'mobile' })`
    top: ${spacingMd}px;
    right: ${spacingMd}px;

    svg {
      width: 24px;
      height: 24px;
    }
  `}
`;

export const StyledImageContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  width: ${mobileImageWidth}px;
  padding-right: ${spacingSm}px;
  box-sizing: content-box;
  line-height: 0;

  ${mediaQueryWidth({ min: 'mobile' })`
    position: static;
    flex: 0 1 30%;
    min-width: 60px;
    max-width: 256px;
    overflow-y: scroll;
  `}

  ${mediaQueryWidth({ min: 'mobile', max: 'tablet' })`
    padding-right: ${spacingMd - spacingXs}px;
  `}

  ${mediaQueryWidth({ min: 'tablet' })`
    padding-right: ${spacingLg - spacingXs}px;
  `}

  a {
    display: block;
  }

  img {
    width: 100%;
    line-height: 1;
    vertical-align: middle;
  }
`;

export const StyledDefaultSvgContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & > svg {
    width: 50px;
    color: ${lightGrey2};
  }

  ${mediaQueryWidth({ min: 'mobile' })`
    padding-top: 50%;
    border: 1px solid ${lightGrey2};
    height: auto;

    & > svg {
      width: 50%;
      transform: translateY(-50%);
    }
  `}
`;

export const StyledContentContainer = styled.div`
  font-size: ${rem(16)};
  line-height: ${rem(26)};

  ${mediaQueryWidth({ min: 'mobile' })`
    flex: 1 0 0%;
  `}
`;

export const StyledTitleContainer = styled.div`
  min-height: 90px;
  margin-left: ${spacingXs}px;
  padding: ${spacingMd}px 30px ${spacingMd}px ${mobileImageWidth + spacingMd + spacingSm}px;
  border-bottom: 2px solid #f4f4f4;

  ${mediaQueryWidth({ min: 'mobile' })`
    min-height: 60px;
    padding: 0 0 ${spacingSm}px 0;
  `}
`;

export const StyledTypeContainer = styled.div`
  display: none;
  line-height: 0;
  ${mediaQueryWidth({ min: 'tablet' })`
    display: block;
  `}
`;

export const StyledType = styled.div`
  display: inline-block;
  font-size: ${rem(10)};
  line-height: ${rem(13)};
  padding: ${rem(2)} ${rem(4)};
  background-color: #eeeeee;
  color: ${black};

  ${mediaQueryWidth({ min: 'mobile' })`
    font-size: ${rem(14)};
    line-height: ${rem(18)};
    padding: ${rem(4)} ${rem(6)};
  `}
`;

export const StyledTitle = styled.span`
  box-sizing: border-box;
  font-size: ${rem(16)};
  font-weight: 800;
  line-height: ${rem(19)};
  margin: ${rem(5)} 0 0 0;
  color: ${black};

  ${mediaQueryWidth({ min: 'mobile' })`
    display: inline-block;
    margin: ${rem(10)} ${rem(13)} 0 0;
    font-size: ${rem(20)};
    line-height: ${rem(24)};
  `}

  ${mediaQueryWidth({ min: 'tablet' })`
    font-size: ${rem(28)};
    line-height: ${rem(40)};
  `}

  & > a {
    color: ${black};

    ${hoverMediaQuery} {
      &:hover {
        color: ${lightBlue};
      }
    }

    ${noHoverMediaQuery} {
      &:active {
        color: ${lightBlue};
      }
    }
  }
`;

export const StyledDate = styled.div`
  font-size: ${rem(14)};
  font-weight: 400;
  line-height: ${rem(23)};
  color: ${grey2};
  margin: ${rem(5)} 0 0 0;

  ${mediaQueryWidth({ min: 'mobile' })`
    display: inline-block;
    font-size: ${rem(17)};
    line-height: ${rem(28)};
  `}

  ${mediaQueryWidth({ min: 'tablet' })`
    font-size: ${rem(20)};
    line-height: ${rem(30)};
  `}
`;

export const StyledVendorLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${lightGrey7};

  ${noHoverMediaQuery} {
    &:active {
      border: 1px solid ${grey40};
      box-shadow: 0 8px 16px 0 rgba(205, 205, 205, 0.5);
    }
  }

  ${hoverMediaQuery} {
    &:hover {
      border: 1px solid ${grey40};
      box-shadow: 0 8px 16px 0 rgba(205, 205, 205, 0.5);
    }
  }

  @media (-ms-high-contrast: none), (-ms-high-contrast: active), @supports not (display: grid) {
    margin: ${gap}px ${gap}px 0 0;
  }
`;

export const StyledPopupDialog = styled(Dialog)`
  .MuiPaper-root {
    width: 100%;
    max-width: calc(100% - ${mobileDialogMargin * 2}px);
    max-height: calc(100% - ${mobileDialogMargin * 2}px);
    margin: ${mobileDialogMargin}px;
    border-radius: 0;
    overflow: visible;
    background-color: transparent;
    box-shadow: initial;

    ${mediaQueryWidth({ min: 'mobile', max: 'tablet' })`
      max-width: calc(100% - ${tabletDialogMargin * 2}px);
      max-height: calc(100% - ${tabletDialogMargin * 2}px);
      margin: ${tabletDialogMargin}px;
    `};

    ${mediaQueryWidth({ min: 'tablet' })`
      width: calc(100% - ${desktopDialogMargin * 2}px);
      max-width: 1300px;
      max-height: calc(100% - ${desktopDialogMargin * 2}px);
      margin: ${desktopDialogMargin}px;
    `};
  }
`;

export const StyledPopupIframe = styled.iframe<{ loaded?: boolean }>`
  width: ${({ loaded }) => (loaded ? 100 : 0)}%;
  height: ${({ loaded }) => (loaded ? 600 : 0)}px;
  border: none;
  visibility: ${({ loaded }) => (loaded ? 'visible' : 'hidden')};
`;

export const StyledPopupLoader = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  width: ${rem(48)};
  min-width: ${rem(48)};
  height: ${rem(48)};
  min-height: ${rem(48)};
  box-sizing: border-box;
  margin: ${rem(48)} auto;
  border: ${rem(8)} solid ${grey10};
  border-top: ${rem(8)} solid ${grey90};
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
