import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { spacingMd, spacingSm } from '../../common/popup.styles';

export const StyledPopupCloseText = styled.div`
  position: absolute;
  z-index: 1;
  top: ${spacingSm}px;
  right: ${spacingSm}px;
  font-size: ${rem(14)};
  line-height: ${rem(18)};

  ${mediaQueryWidth({ min: 'mobile' })`
    top: ${spacingMd}px;
    right: ${spacingMd}px;
  `}
`;
