import * as React from 'react';
import { SITE_TITLE } from '../../common/constants';
import { isMultiCountry } from '../../common/countries';
import { linkMap } from '../../common/footer';
import { getPrefixRedirectedUrl } from '../../common/helpers/prefix';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  NewsletterIcon,
  PinterestIcon,
  TikTokIcon,
  TumblrIcon,
  TwitterIcon,
  YouTubeIcon,
} from '../../common/social-icons';
import { localeAndTranslationObject, Translation, useTranslations } from '../../common/translation';
import { MARKET } from '../../config/browser';
import { SuperLink } from '../super-link/SuperLink';
import { WhiteShield } from '../white-shield/WhiteShield';
import { defaultCopyrightText, defaultPolicyLinks, defaultSocialLinks, defaultWbLinks } from './defaults';
import { SocialLinkProps } from './Footer.props';
import {
  FooterCopyright,
  FooterCopyrightContainer,
  FooterPolicyLinksContainer,
  FooterSocialCollectionContainer,
  FooterSocialCollectionLinks,
  FooterSocialLinks,
  FooterSocialLinksContainer,
  FooterSocialLinksTitle,
  FooterWBLinksContainer,
  FooterWBPolicyContainer,
  StyledFooter,
} from './Footer.styles';
import translations from './translations/footer';
import linkTranslations from './translations/footer-links';
import { updateCountryFooterLinks } from './update-country-footer-links';

const getTranslations = () => {
  const { t } = useTranslations(translations);
  let { localeTranslation } = localeAndTranslationObject(linkTranslations);

  if (isMultiCountry()) {
    localeTranslation = updateCountryFooterLinks(localeTranslation);
  }

  const links = Translation(localeTranslation);
  return { links, t };
};

const getIcon = (icon: string) => {
  switch (icon) {
    case 'facebook':
      return <FacebookIcon className='facebook-icon' />;
    case 'twitter':
      return <TwitterIcon className='twitter-icon' viewBox='0 -70 199 279' />;
    case 'instagram':
      return <InstagramIcon className='instagram-icon' viewBox='0 0 512 512' />;
    case 'pinterest':
      return <PinterestIcon className='pinterest-icon' viewBox='0 -50 199 279' />;
    case 'youtube':
      return <YouTubeIcon className='youtube-icon' />;
    case 'linkedin':
      return <LinkedInIcon className='linkedin-icon' />;
    case 'newsletter':
      return <NewsletterIcon className='newsletter-icon' />;
    case 'tumblr':
      return <TumblrIcon className='tumblr-icon' />;
    case 'tiktok':
      return <TikTokIcon className='tiktok-icon' />;
  }
};

const buildSocialLinks = (socialLinks: SocialLinkProps[]) =>
  socialLinks.map(({ img, target, title, url }: SocialLinkProps) => {
    const icon = getIcon(img);
    const redirectedUrl = getPrefixRedirectedUrl(url);
    return (
      icon && (
        <SuperLink
          aria-label={`${title} - ${SITE_TITLE}`}
          key={redirectedUrl}
          target={target}
          to={redirectedUrl}
          tabIndex={0}
        >
          {/*
            Insert a zero-width space in order to allow Safari to tab
            to this element without modifying MacOS preferences.
          */}
          {icon}&#8203;
        </SuperLink>
      )
    );
  });

const buildCopyright = (currentYear: number, copyrightText: string, collapseCopyright: boolean) => {
  const tradeMark: string = `TM & © ${currentYear} Warner Bros. Entertainment Inc.`;

  if (collapseCopyright) {
    return (
      <>
        {tradeMark}
        <br />
        {copyrightText}.
      </>
    );
  }

  return `${tradeMark} ${copyrightText}.`;
};

export const Footer = () => {
  const { links, t } = getTranslations();

  const buildSocialCollections = () =>
    links('socialCollectionLinks', []).map((collection: any) => (
      <FooterSocialCollectionContainer key={collection.name}>
        <div className='collection-name'>{collection.name}: </div>
        <FooterSocialCollectionLinks aria-label={t('ariaSocialLinks', 'Social links')}>
          {buildSocialLinks(collection.socialLinks)}
        </FooterSocialCollectionLinks>
      </FooterSocialCollectionContainer>
    ));

  const wbLinks = links('wbLinks', defaultWbLinks).map(linkMap);
  const policyLinks = links('policyLinks', defaultPolicyLinks).map(linkMap);
  const socialLinks = buildSocialLinks(links('socialLinks', defaultSocialLinks));
  const marketHasCookieConsent = MARKET !== 'IN' && MARKET !== 'CA';
  // tslint:disable:react-a11y-anchors
  const cookieSettingLinks = (
    <a data-cookie-preference='true' href='javascript:void(0);'>
      Cookie Settings
    </a>
  );
  // tslint:enable:react-a11y-anchors
  const socialTitle = t('socialTitle');
  const socialCollections = buildSocialCollections();
  const collapseCopyright = socialCollections.length > 0;
  const currentYear = new Date().getFullYear();
  const copyrightText = t('copyrightText', defaultCopyrightText).replace(/\.$/, '');
  const copyright = buildCopyright(currentYear, copyrightText, collapseCopyright);

  return (
    <StyledFooter className='footer'>
      <FooterCopyrightContainer collapseCopyright={collapseCopyright}>
        <WhiteShield />
        <FooterCopyright className='footer-copyright'>{copyright}</FooterCopyright>
      </FooterCopyrightContainer>
      {(socialLinks.length > 0 || socialCollections.length > 0) && (
        <FooterSocialLinksContainer collapseCopyright={collapseCopyright} className='footer-social'>
          {socialLinks.length > 0 && (
            <>
              {socialTitle && <FooterSocialLinksTitle>{socialTitle}</FooterSocialLinksTitle>}
              <FooterSocialLinks aria-label={t('ariaSocialLinks', 'Social links')}>{socialLinks}</FooterSocialLinks>
            </>
          )}
          {socialCollections.length > 0 && <>{socialCollections}</>}
        </FooterSocialLinksContainer>
      )}
      <FooterWBPolicyContainer className='footer-policy'>
        <FooterWBLinksContainer aria-label={t('ariaWBLinks', 'WB links')}>{wbLinks}</FooterWBLinksContainer>
        <FooterPolicyLinksContainer aria-label={t('ariaPolicyLinks', 'Policy links')}>
          {policyLinks}
          {marketHasCookieConsent && <>{cookieSettingLinks}</>}
        </FooterPolicyLinksContainer>
      </FooterWBPolicyContainer>
    </StyledFooter>
  );
};
