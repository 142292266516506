import { StyledButton } from './Button.styles';

/*
 * NOTE: Exporting a wrapper component that contains the styled Material-UI button
 * would cause the style props to appear as attributes on the DOM (and lead to warnings).
 * To get around this, export the styled wrapper component that contains
 * the un-styled Material-UI button and use destructuring to remove any style-specific props.
 */
export const Button = StyledButton;

// Allow imports to be consolidated
export { routerButtonValue } from './super-button/SuperButton';
