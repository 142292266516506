import { rem } from 'polished';
import styled from 'styled-components';
import { white } from '../../common/colors';

export const WhiteShieldContainer = styled.div<{ hasFlag?: boolean }>`
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  text-align: center;

  /* Keep specificity low in case of overrides */
  & > svg {
    color: ${white};
    width: ${({ hasFlag }) => rem(hasFlag ? 40 : 35)};
  }
`;
