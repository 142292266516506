import { urlPath } from '../helpers/strings';

export enum ImageRenditionPresets {
  KeyArt = 'keyart',
  HeroBanner = 'hero-banner',
  HeroBannerTablet = 'hero-banner-v2-tablet',
  HeroBannerMobile = 'hero-banner-v2-mobile',
  Promo = 'promo',
  ArticlePromo = 'article-promo',
  MobileAppGamesSquare = 'mobile-app-games-square',
  Gallery = 'gallery-v2',
  GalleryTablet = 'gallery-v2-tablet',
  GalleryMobile = 'gallery-v2-mobile',
}

export class ImageRendition {
  public static getPresetSuffix(pathname: string): string {
    const extRegex = new RegExp(/(?:\.([^.]+))?$/, 'gm');
    const extRes = extRegex.exec(pathname);
    if (!extRes) {
      return '';
    }
    const [, fileExt] = extRes;
    switch (fileExt.toLowerCase()) {
      case 'png':
        return '-png';
      case 'jpg':
      case 'jpeg':
        return '-jpeg';
      default:
        return '-png';
    }
  }

  constructor(private filesFolderPath: string, private baseurl: string, private assetsUrl: string) {}

  public getImageRenditionURL(url: string, preset: ImageRenditionPresets) {
    try {
      if (!url) {
        return '';
      }

      const assetsHost = new URL(this.assetsUrl, 'https://www.wb.com').host;
      const urlHost = new URL(encodeURI(url), 'https://www.wb.com').host;
      if (assetsHost !== urlHost && !url.startsWith('public://')) {
        return url;
      }

      const strippedPath = urlPath(url).replace(this.filesFolderPath, '');

      if (!Object.values(ImageRenditionPresets).includes(preset)) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(`Unrecognised preset '${preset}' for '${strippedPath}'`);
      }

      const suffix = ImageRendition.getPresetSuffix(strippedPath);
      return `${this.baseurl}/${preset}${suffix}${strippedPath}`;
    } catch (e) {
      // Return original url if IRS one cannot be created for whatever reason
      return url;
    }
  }
}
