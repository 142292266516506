import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRoutePath } from 'react-static';
import CloseIcon from '../../assets/images/icons/svg/close.svg';
import { IS_SERVER } from '../../common/constants';
import { isMultiCountry } from '../../common/countries';
import { hasLocalStorage, hasSessionStorage } from '../../common/helpers/window';
import {
  StyledCloseButton,
  StyledPopup,
  StyledPopupDialog,
  StyledPopupIframe,
  StyledPopupLoader,
} from '../../common/popup.styles';
import { useTranslations } from '../../common/translation';
import { MARKET } from '../../config/browser';
import { StyledPopupCloseText } from './ConditionalPopup.styles';
import translations from './translations';

const popupConfig: { [market: string]: { iframeUrl: string; paths: string[] } } = {};

const popupVisits = new Set<string>();
const popupVisitSessionKey = 'visited';
const popupSubmittedLocalKey = 'jlsc';
const popupIframeId = 'popup-iframe';

export const setVisitItem = (str: string) => sessionStorage.setItem(popupVisitSessionKey, str);
export const getVisitItem = () => sessionStorage.getItem(popupVisitSessionKey);

export const hasSessionVisit = (path: string): boolean => {
  if (!hasSessionStorage()) {
    return false;
  }

  const visited = getVisitItem();
  if (!visited) {
    return false;
  }

  try {
    const parsed = JSON.parse(visited);
    return Array.isArray(parsed) && parsed.includes(path);
  } catch (e) {
    return false;
  }
};

export const saveSessionVisit = (path: string) => {
  if (!hasSessionStorage()) {
    return false;
  }

  const updateVisit = (paths: string[] = [path]) => setVisitItem(JSON.stringify(paths));

  const visited = getVisitItem();
  if (!visited) {
    updateVisit();
    return;
  }

  try {
    const paths: string[] = JSON.parse(visited);
    if (!paths.includes(path)) {
      paths.push(path);
      updateVisit(paths);
    }
  } catch (e) {
    updateVisit();
  }
};

const saveFormSubmit = () => {
  if (hasLocalStorage()) {
    localStorage.setItem(popupSubmittedLocalKey, 'true');
  }
};

export const hasAnySessionVisit = (): boolean => {
  if (!hasSessionStorage()) {
    return false;
  }

  const visited = getVisitItem();
  if (!visited) {
    return false;
  }

  try {
    const parsed = JSON.parse(visited);
    return Array.isArray(parsed) && parsed.length > 0;
  } catch (e) {
    return false;
  }
};

const hasVisitedPath = (path: string): boolean => hasSessionVisit(path) || popupVisits.has(path);

const hasSeenPopup = (): boolean => hasAnySessionVisit() || popupVisits.size > 0;

const hasSubmittedForm = (): boolean => {
  if (hasLocalStorage()) {
    return localStorage.getItem(popupSubmittedLocalKey) === 'true';
  }

  return false;
};

const visitPath = (path: string) => {
  saveSessionVisit(path);
  popupVisits.add(path);
};

export const sanitizePath = (path: string = ''): string => {
  let sanitizedPath = path;

  if (isMultiCountry()) {
    sanitizedPath = sanitizedPath.replace(/^[a-z]{2}-[a-z]{2}/i, '');
  }

  if (!sanitizedPath.startsWith('/')) {
    sanitizedPath = `/${sanitizedPath}`;
  }

  return sanitizedPath;
};

const pushToDataLayer = (event: string) => {
  if (!IS_SERVER) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      url: window.location.href,
    });
  }
};

const getOrigin = (url: string) => new URL(url).origin;

declare const window: any;

export const ConditionalPopup = () => {
  const [iframeIsLoaded, setIframeIsLoaded] = useState<boolean>(false);
  const [shouldPreventClose, setShouldPreventClose] = useState<boolean>(!hasSeenPopup());
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const [isPopupEnabled, setIsPopupEnabled] = useState<boolean>(false);
  const routePath = useRoutePath('');
  const { t } = useTranslations(translations);

  const path = sanitizePath(routePath);

  const removePopup = () => {
    setIsPopupVisible(false);
    setIframeIsLoaded(false);
  };

  const onSubmit = () => {
    pushToDataLayer('jlsc-submit');
    saveFormSubmit();
  };

  const onPopupClose = () => {
    pushToDataLayer('jlsc-close');
    removePopup();
  };

  const onIframeLoad = () => {
    if (!iframeIsLoaded) {
      pushToDataLayer('jlsc-view');
      setIframeIsLoaded(true);
    }
  };

  const onDialogClose = () => {
    if (!shouldPreventClose) {
      onPopupClose();
    }
  };

  useEffect(() => {
    if (window.JLSC) {
      setIsPopupEnabled(true);
    } else {
      window.JLSC = () => {
        setIsPopupEnabled(true);
      };
    }

    const handleMessage = (event: MessageEvent) => {
      if (!popupConfig.hasOwnProperty(MARKET) || event.origin !== getOrigin(popupConfig[MARKET].iframeUrl)) {
        return;
      }

      switch (event.data.action) {
        case 'close':
          onPopupClose();
          break;
        case 'submit':
          onSubmit();
          break;
      }
    };

    window.addEventListener('message', handleMessage, false);

    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, []);

  useEffect(() => {
    if (hasSubmittedForm()) {
      removePopup();
      return;
    }

    const pathMatches = popupConfig.hasOwnProperty(MARKET) && popupConfig[MARKET].paths.includes(path);
    if (!pathMatches) {
      removePopup();
      return;
    }

    const visited = hasVisitedPath(path);
    const updatedHasPopup = !visited && isPopupEnabled;
    setIsPopupVisible(updatedHasPopup);
    setIframeIsLoaded(false);

    if (updatedHasPopup) {
      visitPath(path);
    }
  }, [path, isPopupEnabled]);

  useEffect(() => {
    if (iframeIsLoaded && shouldPreventClose) {
      const keepOpenSeconds = 3;
      const handle = setTimeout(() => {
        setShouldPreventClose(false);
      }, keepOpenSeconds * 1000);

      return () => {
        clearTimeout(handle);
      };
    }
  }, [iframeIsLoaded]);

  if (IS_SERVER || !isPopupVisible) {
    return null;
  }

  const cornerElement = shouldPreventClose ? (
    <StyledPopupCloseText>{t('waitToClose', 'Close in 3 seconds')}</StyledPopupCloseText>
  ) : (
    <StyledCloseButton onClick={onPopupClose}>
      <CloseIcon />
    </StyledCloseButton>
  );

  return (
    <StyledPopupDialog onClose={onDialogClose} open={true}>
      <StyledPopup data-testid='jlsc-popup' hidePadding={true} show={true}>
        {iframeIsLoaded && cornerElement}
        <StyledPopupLoader isVisible={!iframeIsLoaded} />
        <StyledPopupIframe
          id={popupIframeId}
          loaded={iframeIsLoaded}
          onLoad={onIframeLoad}
          src={popupConfig[MARKET].iframeUrl}
          title='JLSC'
        />
      </StyledPopup>
    </StyledPopupDialog>
  );
};
