import * as React from 'react';
import { Helmet } from 'react-helmet';
import { LoadGTMProps } from './LoadGTM.props';

export const LoadGTM = ({ id }: LoadGTMProps) => (
  <Helmet>
    <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${id}');`}</script>
    <noscript>{`<iframe src='https://www.googletagmanager.com/ns.html?id=${id}' width='0' height='0' />`}</noscript>
  </Helmet>
);
