import { getCountryCodeFromPath } from '../../common/helpers/countrySelection';

const countryFooterLinks = {
  AR: {
    socialLinks: [
      {
        url: 'https://www.facebook.com/warnerbrospicturesargentina/',
        target: '_blank',
        title: 'Facebook',
        img: 'facebook',
      },
      {
        url: 'https://twitter.com/WBPictures_Ar',
        target: '_blank',
        title: 'Twitter',
        img: 'twitter',
      },
      {
        url: 'https://www.instagram.com/wbpictures.ar/',
        target: '_blank',
        title: 'Instagram',
        img: 'instagram',
      },
      {
        url: 'https://www.linkedin.com/company/warner-bros--entertainment',
        target: '_blank',
        title: 'LinkedIn',
        img: 'linkedin',
      },
      {
        url: 'https://www.youtube.com/user/WarnerBrosPicturesLA/',
        target: '_blank',
        title: 'YouTube',
        img: 'youtube',
      },
      {
        url: '/newsletter',
        title: 'Newsletter',
        img: 'newsletter',
      },
    ],
  },
  AT: {
    policyLinks: [
      {
        url: 'https://policies.warnerbros.com/terms/de-at/',
        title: 'Nutzungsbedingungen',
        target: '_blank',
      },
      {
        url: 'https://policies.warnerbros.com/privacy/de-at/',
        title: 'Privacy Policy/Datenschutz',
        target: '_blank',
      },
    ],
  },
  CL: {
    socialLinks: [
      {
        url: 'https://www.facebook.com/warnerbrospictureschile/',
        target: '_blank',
        title: 'Facebook',
        img: 'facebook',
      },
      {
        url: 'https://twitter.com/WarnerBrosChile',
        target: '_blank',
        title: 'Twitter',
        img: 'twitter',
      },
      {
        url: 'https://www.instagram.com/wbpictureslatam/',
        target: '_blank',
        title: 'Instagram',
        img: 'instagram',
      },
      {
        url: 'https://www.linkedin.com/company/warner-bros--entertainment',
        target: '_blank',
        title: 'LinkedIn',
        img: 'linkedin',
      },
      {
        url: 'https://www.youtube.com/user/WarnerBrosPicturesLA/',
        target: '_blank',
        title: 'YouTube',
        img: 'youtube',
      },
      {
        url: '/newsletter',
        title: 'Newsletter',
        img: 'newsletter',
      },
    ],
  },
  MX: {
    policyLinks: [
      {
        url: 'https://policies.warnerbros.com/terms/es-mx/',
        title: 'Términos y condiciones',
        target: '_blank',
      },
      {
        url: 'https://policies.warnerbros.com/privacy/es-mx/',
        title: 'Política de privacidad',
        target: '_blank',
      },
    ],
    socialLinks: [
      {
        url: 'https://www.facebook.com/WarnerBrosPicturesMexico/?fref=ts',
        target: '_blank',
        title: 'Facebook',
        img: 'facebook',
      },
      {
        url: 'https://twitter.com/WBPictures_Mx',
        target: '_blank',
        title: 'Twitter',
        img: 'twitter',
      },
      {
        url: 'https://www.instagram.com/warnerbrosmx/',
        target: '_blank',
        title: 'Instagram',
        img: 'instagram',
      },
      {
        url: '/newsletter',
        title: 'Newsletter',
        img: 'newsletter',
      },
    ],
  },
};

export const updateCountryFooterLinks = (linkTranslations: any) => {
  const countryCode = getCountryCodeFromPath();

  if (countryCode && countryFooterLinks[countryCode!]) {
    return Object.assign({}, linkTranslations, countryFooterLinks[countryCode!]);
  }

  return linkTranslations;
};
