import * as React from 'react';
import { Head } from 'react-static';
import { SITE_TITLE } from '../../common/constants';
import { HeadTitleProps } from './HeadTitle.props';

export const HeadTitle = ({ title }: HeadTitleProps) => {
  let fullTitle: string = SITE_TITLE;

  if (title) {
    fullTitle = `${fullTitle} | ${title}`;
  }

  return (
    <Head>
      <title>{fullTitle}</title>
    </Head>
  );
};
