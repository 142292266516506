import * as React from 'react';
import { SuperLink } from '../super-link/SuperLink';
import { BrowserLogoProps } from './BrowserLogo.props';
import { StyledBrowserLogo, StyledBrowserLogoName } from './BrowserLogo.styles';

export const BrowserLogo = ({ icon, name, to }: BrowserLogoProps) => (
  <StyledBrowserLogo>
    <SuperLink target='_blank' to={to}>
      {icon}
      <StyledBrowserLogoName>{name}</StyledBrowserLogoName>
    </SuperLink>
  </StyledBrowserLogo>
);
