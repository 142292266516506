import styled, { keyframes } from 'styled-components';

const ellipsis = keyframes`
  to {
    width: 2em;
  }
`;

export const LoadingEllipsis = styled.div`
  visibility: visible;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);

  &::after {
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
    animation: ${ellipsis} steps(4, end) 1s infinite;
    content: '. . .';
    width: 0;
    height: 1em;
  }
`;
