import browser from 'browser-detect';
import { BrowserDetectInfo } from 'browser-detect/dist/types/browser-detect.interface';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ChromeIcon from '../../assets/images/icons/svg/chrome.svg';
import CloseIcon from '../../assets/images/icons/svg/close.svg';
import FirefoxIcon from '../../assets/images/icons/svg/firefox.svg';
import EdgeIcon from '../../assets/images/icons/svg/microsoft-edge.svg';
import SafariIcon from '../../assets/images/icons/svg/safari.svg';
import { hasSessionStorage } from '../../common/helpers/window';
import { useTranslations } from '../../common/translation';
import { BrowserLogo } from './BrowserLogo';
import { BrowserNoticeProps } from './BrowserNotice.props';
import {
  StyledBNCloseButton,
  StyledBNHeader,
  StyledBNLogoList,
  StyledBNMessage,
  StyledBrowserNotice,
} from './BrowserNotice.styles';
import translations from './translations';

const supportedBrowserList = { chrome: 58, safari: 10.1, firefox: 54, edge: 16 };

// prettier-ignore
// tslint:disable-next-line:max-line-length
const defaultMessage = "You're using a browser that we don't support. We recommend that you upgrade to the latest version or use another browser to have a better experience on our site.";

export const BrowserNotice = ({ storyFlag }: BrowserNoticeProps) => {
  const { t } = useTranslations(translations);
  const [shouldShowNotice, setShouldShowNotice] = useState<boolean>(false);
  const closeNotice = () => {
    if (hasSessionStorage()) {
      sessionStorage.setItem('browser_notice_received', 'true');
      setShouldShowNotice(false);
    }
  };

  useEffect(() => {
    if (hasSessionStorage() && sessionStorage.getItem('browser_notice_received') === 'true') {
      setShouldShowNotice(false);
      return;
    }

    const browserDetails: BrowserDetectInfo = (browser && browser()) || {};
    const browserName: string = browserDetails.name || '';
    const browserVersion: number = browserDetails.versionNumber || 0;
    const browserSupported: boolean =
      supportedBrowserList.hasOwnProperty(browserName) && browserVersion >= supportedBrowserList[browserName];
    setShouldShowNotice(!browserSupported || Boolean(storyFlag));
  }, []);

  if (!shouldShowNotice) {
    return null;
  }

  return (
    <StyledBrowserNotice>
      <StyledBNHeader>
        <h2>{t('title', 'Improve Your Experience')}</h2>
        <StyledBNCloseButton onClick={closeNotice}>
          <CloseIcon />
        </StyledBNCloseButton>
      </StyledBNHeader>
      <StyledBNMessage>{t('message', defaultMessage)}</StyledBNMessage>
      <StyledBNLogoList>
        <BrowserLogo icon={<ChromeIcon />} name='Chrome' to={'https://www.google.com/chrome'} />
        <BrowserLogo icon={<SafariIcon />} name='Safari' to={'https://support.apple.com/en-us/HT204416'} />
        <BrowserLogo icon={<EdgeIcon />} name='Edge' to={'https://www.microsoft.com/windows/microsoft-edge'} />
        <BrowserLogo icon={<FirefoxIcon />} name='Firefox' to={'https://www.mozilla.org/firefox'} />
      </StyledBNLogoList>
    </StyledBrowserNotice>
  );
};
