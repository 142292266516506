import { Routes } from '../../common/constants';
import { FooterLinkProps, SocialLinkProps } from './Footer.props';

export const defaultPolicyLinks: FooterLinkProps[] = [
  {
    target: '_blank',
    title: 'Terms of Use',
    url: 'https://policies.warnerbros.com/terms/en-us/',
  },
  {
    target: '_blank',
    title: 'Privacy Policy',
    url: 'https://policies.warnerbros.com/privacy/',
  },
  {
    target: '_blank',
    title: 'Sourced Traffic Disclosure',
    url: '/content/sourced-traffic-disclosure',
  },
  {
    target: '_blank',
    title: 'Ad Choices',
    url: 'https://policies.warnerbros.com/privacy/en-us/#adchoices',
  },
  {
    target: '_blank',
    title: 'Accessibility',
    url: 'https://policies.warnerbros.com/terms/en-us/#accessibility',
  },
];

export const defaultCopyrightText = 'All rights reserved';

export const defaultSocialLinks: SocialLinkProps[] = [
  {
    img: 'facebook',
    target: '_blank',
    title: 'Facebook - www.warnerbros.com',
    url: 'https://www.facebook.com/warnerbrosent/',
  },
  {
    img: 'twitter',
    target: '_blank',
    title: 'Twitter - www.warnerbros.com',
    url: 'https://twitter.com/Warnerbros/',
  },
  {
    img: 'pinterest',
    target: '_blank',
    title: 'Pinterest - www.warnerbros.com',
    url: 'https://www.pinterest.com/warnerbrosent/',
  },
  {
    img: 'instagram',
    target: '_blank',
    title: 'Instagram - www.warnerbros.com',
    url: 'https://www.instagram.com/warnerbrosentertainment/',
  },
  {
    img: 'linkedin',
    target: '_blank',
    title: 'LinkedIn - www.warnerbros.com',
    url: 'https://www.linkedin.com/company/warner-bros--entertainment/',
  },
  {
    img: 'youtube',
    target: '_blank',
    title: 'YouTube - www.warnerbros.com',
    url: 'https://www.youtube.com/user/WarnerBrosPictures/',
  },
  {
    img: 'newsletter',
    title: 'Newsletter - www.warnerbros.com',
    url: '/newsletter',
  },
];
export const defaultSocialTitle = 'Follow Us';
export const defaultWbLinks: FooterLinkProps[] = [
  {
    title: 'Company',
    url: '/company',
  },
  {
    title: 'Press Releases',
    url: Routes.pressReleases,
  },
  {
    target: '_blank',
    title: 'Careers',
    url: 'https://www.warnerbroscareers.com/',
  },
  {
    title: 'International',
    url: Routes.international,
  },
  {
    title: 'Help',
    url: '/help/faq',
  },
];
